import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function OrderView() {
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log({ location: location.state.data });

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Order view" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Order detail" {...a11yProps(0)} />
              <Tab label="Order item" {...a11yProps(1)} />
            </Tabs>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                size="large"
                variant="outlined"
                onClick={() => navigate("/orderlist")}
              >
                Order list
              </Button>
            </Box>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <Box
              sx={{
                "& > :not(style)": {
                  m: 1,
                  p: 2,
                },
              }}
            >
              <Paper elevation={3}>
                <Typography
                  sx={{ textTransform: "uppercase", marginBottom: 3 }}
                  color="indigo"
                >
                  Order Id: {location.state.data.id}
                </Typography>
                <Typography
                  sx={{ textTransform: "uppercase", marginBottom: 3 }}
                  color="indigo"
                >
                  Created on: {location.state.data.createdDate.slice(0, 16)}{" "}
                  {location.state.data.createdDate.slice(16, 25)}
                </Typography>
                <Typography
                  sx={{ textTransform: "uppercase", marginBottom: 3 }}
                  color="indigo"
                >
                  Status: {location.state.data.status}
                </Typography>
                <Typography
                  sx={{ textTransform: "uppercase", marginBottom: 3 }}
                  color="indigo"
                >
                  Payment method: {location.state.data.paymentStatus[0]?.method}
                </Typography>
                <Typography
                  sx={{ textTransform: "uppercase", marginBottom: 3 }}
                  color="indigo"
                >
                  Payment status:{" "}
                  {location.state.data.paymentStatus[0]?.paymentStatus}
                </Typography>
                <Typography
                  sx={{ textTransform: "uppercase", marginBottom: 3 }}
                  color="indigo"
                >
                  Total amount:{" "}
                  {location.state.data.paymentStatus[0]?.totalAmnt}
                </Typography>
              </Paper>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {location.state.data.orderItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  "& > :not(style)": {
                    m: 1,
                    p: 2,
                  },
                }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography color="indigo" sx={{ marginBottom: 3 }}>
                      Product name: {item.productName}
                    </Typography>
                    <Typography
                      sx={{ textTransform: "uppercase", marginBottom: 3 }}
                      color="indigo"
                    >
                      SKU: {item.sku}
                    </Typography>
                    <Typography color="indigo" sx={{ marginBottom: 3 }}>
                      Sale price: {item.salePrice}
                    </Typography>
                    <Typography color="indigo" sx={{ marginBottom: 3 }}>
                      Quantity: {item.qty}
                    </Typography>
                    <Typography color="indigo" sx={{ marginBottom: 3 }}>
                      Delivery date: {item.deliveryDate}
                    </Typography>
                    <Typography color="indigo" sx={{ marginBottom: 3 }}>
                      Status: {item.itemStatus}
                    </Typography>
                    {item.variants.map((el, i) => (
                      <Box sx={{ display: "flex" }} key={i}>
                        <Typography
                          sx={{ marginRight: 2, marginBottom: 3 }}
                          color="indigo"
                        >
                          {el.variantKey} :
                        </Typography>
                        <Typography color="indigo">
                          {el.variantValue}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box>
                    <Typography color="indigo">Product image:</Typography>

                    <Avatar
                      alt=""
                      src={item.thumb}
                      variant="rounded"
                      sx={{
                        width: 366,
                        height: 386,
                        display: "-webkit-inline-flex",
                        marginBottom: 3,
                      }}
                    />
                  </Box>
                </Paper>
              </Box>
            ))}
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
}
