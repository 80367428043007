import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import Skeleton from "@mui/material/Skeleton";
import { visuallyHidden } from "@mui/utils";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Zoom,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Brightness1, Search, Visibility } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import {
  getDocs,
  collection,
  query,
  updateDoc,
  doc,
  where,
} from "firebase/firestore";

import { db } from "../../firebase";
import { baseUrl } from "../../baseUrl";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "code",
    numeric: true,
    disablePadding: false,
    label: "Style code",
  },
  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Thumbnail",
  },
  {
    id: "stock",
    numeric: true,
    disablePadding: false,
    label: "Stock",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Created date",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const navigate = useNavigate();

  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Typography
            sx={{ flex: "1 1 20%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Products
          </Typography>
          <Box sx={{ padding: "1em" }}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Search"
              variant="outlined"
              placeholder="Product name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{ marginRight: "1em", width: "24em" }}
              onChange={(e) => props.setSearchText(e.target.value)}
              value={props.searchText}
            />
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker slotProps={{ textField: { size: "small" } }} />
            </LocalizationProvider> */}
          </Box>
          <Button
            variant="contained"
            size="medium"
            color="success"
            startIcon={<AddIcon />}
            onClick={() => navigate("/addnewproduct")}
          >
            Add New
          </Button>
        </>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ProductList() {
  let userdetail = JSON.parse(localStorage.getItem("userdetail"));

  const navigate = useNavigate();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [productList, setProductList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [reasonOpen, setReasonOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = productList.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productList.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(productList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [productList, order, orderBy, page, rowsPerPage]
  );

  React.useEffect(() => {
    if (searchText !== "") {
      handleSearch();
    } else {
      getProducts();
    }
  }, [searchText]);

  const handleSearch = async () => {
    setLoading(true);

    let list = productList.filter((e) =>
      e.productName.toLowerCase().includes(searchText)
    );

    setProductList(list);
    setLoading(false);
  };

  const getProducts = async () => {
    if (userdetail[0]?.role === "admin") {
      setLoading(true);
      const q = query(collection(db, "dashboard_product"));
      await getDocs(q).then((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setProductList(newData);
        setLoading(false);
      });
    } else {
      setLoading(true);
      const q = query(
        collection(db, "dashboard_product"),
        where("vendorId", "==", userdetail[0]?.id)
      );
      await getDocs(q).then((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setProductList(newData);
        setLoading(false);
      });
    }
  };
  const updateProductStatus = async (id, status) => {
    await updateDoc(
      doc(db, "dashboard_product", id),

      {
        status: status,
        drafted: false,
        qaChecked: false,
        isNew: false,
      }
    );
    window.location.reload();
  };

  const draftProduct = async (id, status) => {
    await updateDoc(doc(db, "dashboard_product", id), {
      drafted: status,
      status: false,
      qaChecked: true,
    });
    window.location.reload();
  };

  const handleEditProduct = (row) => {
    navigate("/productedit", { state: { data: row } });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Product list" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              searchText={searchText}
              setSearchText={setSearchText}
            />
            <TableContainer>
              {loading === true ? (
                <Box sx={{ width: "100%" }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              ) : (
                <Table aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={productList.length}
                  />

                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.productName);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              onClick={(event) =>
                                handleClick(event, row.productName)
                              }
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: 200,
                              }}
                            >
                              {row.productName}
                              {row.drafted === true &&
                              row.status === false &&
                              row.qaChecked === true ? (
                                <Chip
                                  icon={
                                    <Brightness1
                                      style={{
                                        width: 5,
                                        height: 5,
                                        color:
                                          row.drafted === true
                                            ? "#B2B625"
                                            : "#E4EA36",
                                      }}
                                    />
                                  }
                                  label={row.drafted ? "In draft" : null}
                                  sx={
                                    row.drafted === true
                                      ? {
                                          backgroundColor: "#F8FAB0",

                                          color: "#000",
                                          fontSize: 10,
                                          height: 20,
                                        }
                                      : null
                                  }
                                  variant="outlined"
                                />
                              ) : null}

                              {row.drafted === false &&
                              row.status === true &&
                              row.qaChecked === true ? (
                                <Chip
                                  icon={
                                    <Brightness1
                                      style={{
                                        width: 5,
                                        height: 5,
                                        color:
                                          row.status === true
                                            ? "#37B046"
                                            : "#E4FFE8",
                                      }}
                                    />
                                  }
                                  label={row.status ? "Active" : "Inactive"}
                                  sx={
                                    row.status === true
                                      ? {
                                          backgroundColor: "#E4FFE8",

                                          color: "#000",
                                          fontSize: 10,
                                          height: 20,
                                        }
                                      : null
                                  }
                                  variant="outlined"
                                />
                              ) : null}
                              {row.drafted === false &&
                              row.status === false &&
                              row.qaChecked === false ? (
                                <Chip
                                  icon={
                                    <Brightness1
                                      style={{
                                        width: 5,
                                        height: 5,
                                        color:
                                          row.status === false
                                            ? "#F54040"
                                            : "#F54040",
                                      }}
                                    />
                                  }
                                  label={
                                    row.status === true ? "Active" : "Inactive"
                                  }
                                  sx={
                                    row.status === false
                                      ? {
                                          backgroundColor: "#FFE4E4",

                                          color: "#000",
                                          fontSize: 10,
                                          height: 20,
                                        }
                                      : null
                                  }
                                  variant="outlined"
                                />
                              ) : null}

                              {row.drafted === false &&
                              row.status === false &&
                              row.qaChecked === true &&
                              row.isRejected === true ? (
                                <>
                                  <Chip
                                    icon={
                                      <Brightness1
                                        style={{
                                          width: 5,
                                          height: 5,
                                          color:
                                            row.isRejected === true
                                              ? "#F54040"
                                              : "#F54040",
                                        }}
                                      />
                                    }
                                    label={
                                      row.isRejected === true
                                        ? "Rejected"
                                        : "Inactive"
                                    }
                                    sx={
                                      row.isRejected === true
                                        ? {
                                            backgroundColor: "#FFE4E4",

                                            color: "#000",
                                            fontSize: 10,
                                            height: 20,
                                          }
                                        : null
                                    }
                                    variant="outlined"
                                  />
                                </>
                              ) : null}

                              {row.qaChecked === false &&
                              row.drafted !== true &&
                              row.status === true ? (
                                <Chip
                                  icon={
                                    <Brightness1
                                      style={{
                                        width: 5,
                                        height: 5,
                                        color:
                                          row.drafted === true
                                            ? "#37B046"
                                            : "#5E8AD2",
                                      }}
                                    />
                                  }
                                  label={
                                    row.qaChecked === false ? "In qa" : null
                                  }
                                  sx={
                                    row.qaChecked === false
                                      ? {
                                          backgroundColor: "#C0D8FF",
                                          color: "#000",
                                          fontSize: 10,
                                          height: 20,
                                        }
                                      : null
                                  }
                                  variant="outlined"
                                />
                              ) : null}
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <Typography sx={{ textTransform: "uppercase" }}>
                              {row.productStyleCode}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Avatar
                              alt=""
                              src={row?.variants !== undefined ? row?.variants[0]?.thumb : null}
                              variant="rounded"
                              sx={{
                                width: 56,
                                height: 76,
                                display: "-webkit-inline-flex",
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {row.variants?.map((s) => s.value)?.join(", ")} :{" "}
                            {row.variants?.map((s) => s.qty)?.join(", ")}
                          </TableCell>
                          <TableCell align="right">28-07-23</TableCell>
                          <TableCell align="right">
                            <Box>
                              <Tooltip
                                title={
                                  row.status === true ? "Inactive" : "Active"
                                }
                                TransitionComponent={Zoom}
                                placement="top"
                              >
                                <Switch
                                  checked={row.status === true ? true : false}
                                  color="success"
                                  sx={{
                                    "&.MuiSwitch-root .MuiSwitch-switchBase": {
                                      color: "red",
                                    },

                                    "&.MuiSwitch-root .Mui-checked": {
                                      color: "green",
                                    },
                                  }}
                                  onChange={() =>
                                    updateProductStatus(
                                      row.id,
                                      row.status === true ? false : true
                                    )
                                  }
                                />
                              </Tooltip>
                              <Tooltip
                                title="Edit"
                                TransitionComponent={Zoom}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => handleEditProduct(row)}
                                >
                                  <ModeEditIcon color="primary" />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                title="Draft"
                                TransitionComponent={Zoom}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => draftProduct(row.id, true)}
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </Tooltip>
                              <Link
                                href={`${baseUrl}/productdetail/${row.id}`}
                                target="_blank"
                              >
                                <Tooltip
                                  title="View on website"
                                  TransitionComponent={Zoom}
                                  placement="top"
                                >
                                  <IconButton>
                                    <Visibility color="info" />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </Box>
                            {row.drafted === false &&
                            row.status === false &&
                            row.qaChecked === true &&
                            row.isRejected === true ? (
                              <>
                                <IconButton onClick={() => setReasonOpen(true)}>
                                  <Typography
                                    sx={{ marginRight: 1, color: "Highlight" }}
                                  >
                                    Reject reason
                                  </Typography>
                                  <Visibility color="info" />
                                </IconButton>

                                <Dialog
                                  open={reasonOpen}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle
                                    id="alert-dialog-title"
                                    sx={{ width: 500 }}
                                  >
                                    {"Reason for reject"}
                                  </DialogTitle>
                                  <DialogContent>
                                    {row.rejectedMsg.map((e, index) => (
                                      <Typography key={index}>
                                        ({index + 1}) {e.msg}
                                      </Typography>
                                    ))}
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      onClick={() => setReasonOpen(false)}
                                    >
                                      Close
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={productList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
