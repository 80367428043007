import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./screens/home/Dashboard";
import ProductList from "./screens/products/ProductListScreen";
import AddNewProduct from "./screens/products/AddNewProduct";
import PublishedProduct from "./screens/products/PublishedProduct";
import QaProduct from "./screens/products/QaProduct";
import DraftedProduct from "./screens/products/DraftedProduct";
import OrderList from "./screens/order/OrderListScreen";
import NewOrders from "./screens/order/NewOrders";
import CompletedOrders from "./screens/order/CompletedOrders";
import CancelledOrders from "./screens/order/CancelledOrders";
import InProcessOrders from "./screens/order/InProcessOrders";
import ProductInventoryScreen from "./screens/inventory/ProductInventoryScreen";
import ProductOutOfStock from "./screens/inventory/ProductOutOfStock";
import UsersList from "./screens/users/UsersList";
import VendorList from "./screens/users/VendorList";
import QaList from "./screens/users/QaList";
import CustomersList from "./screens/users/CustomersList";
import CategoryListScreen from "./screens/category/CategoryListScreen";
import AddNewCategory from "./screens/category/AddNewCategory";
import AddNewAttribute from "./screens/attributes/AddNewAttribute";
import AttributeListScreen from "./screens/attributes/AttributeListScreen";
import ProductEdit from "./screens/products/ProductEdit";
import RejectedProduct from "./screens/products/RejectedProduct";
import LoginScreen from "./screens/login/LoginScreen";
import SignUp from "./screens/signup/SignUp";
import VendorVerification from "./screens/users/VendorVerification";
import Checkout from "./screens/order/createOrder/Checkout";
import OrderView from "./screens/order/OrderView";
import WebsiteBanners from "./screens/websiteBanners/WebsiteBanners";
import AddNewBanner from "./screens/websiteBanners/AddNewBanner";
import AddNewFit from "./screens/fit/AddNewFit";
import AddNewFabric from "./screens/fabric/AddNewFabric";
import FitListScreen from "./screens/fit/FitList";
import FabricListScreen from "./screens/fabric/FabricList";
import CouponsListScreen from "./screens/coupons/CouponsList";
import AddNewCoupons from "./screens/coupons/AddNewCoupons";
import BlogsList from "./screens/blogs/BlogsList";
import AddNewBlog from "./screens/blogs/AddNewBlog";
import AddNewBlogCategory from "./screens/blogs/AddNewBlogCategory";
import BlogCategoryList from "./screens/blogs/BlogCategoryList";
import EditBlog from "./screens/blogs/EditBlog";
import AddNewService from "./screens/services/AddNewService";
import ServiceList from "./screens/services/ServiceList";
import EditService from "./screens/services/EditService";
import AboutUs from "./screens/aboutUs/AboutUs";
import Policies from "./screens/policies/Policies";
import ServiceRequestList from "./screens/services/ServiceRequests";

function App() {
  const PrivateRoute = ({ children }) => {
    let userDetail = localStorage.getItem("userdetail");

    return userDetail !== null ? children : <Navigate to="/" />;
  };
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<LoginScreen />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute children={<Dashboard />} />}
        />
        <Route
          path="/productlist"
          element={<PrivateRoute children={<ProductList />} />}
        />
        <Route
          path="/addnewproduct"
          element={<PrivateRoute children={<AddNewProduct />} />}
        />
        <Route
          path="/publishedproduct"
          element={<PrivateRoute children={<PublishedProduct />} />}
        />
        <Route
          path="/qaproduct"
          element={<PrivateRoute children={<QaProduct />} />}
        />
        <Route
          path="/draftedproduct"
          element={<PrivateRoute children={<DraftedProduct />} />}
        />
        <Route
          path="/orderlist"
          element={<PrivateRoute children={<OrderList />} />}
        />
        <Route
          path="/neworders"
          element={<PrivateRoute children={<NewOrders />} />}
        />
        <Route
          path="/completedorders"
          element={<PrivateRoute children={<CompletedOrders />} />}
        />
        <Route
          path="/cancelledorders"
          element={<PrivateRoute children={<CancelledOrders />} />}
        />
        <Route
          path="/inprocessorders"
          element={<PrivateRoute children={<InProcessOrders />} />}
        />
        <Route
          path="/productinventory"
          element={<PrivateRoute children={<ProductInventoryScreen />} />}
        />
        <Route
          path="/productoutofstock"
          element={<PrivateRoute children={<ProductOutOfStock />} />}
        />
        <Route
          path="/userlist"
          element={<PrivateRoute children={<UsersList />} />}
        />
        <Route
          path="/vendorlist"
          element={<PrivateRoute children={<VendorList />} />}
        />
        <Route
          path="/qalist"
          element={<PrivateRoute children={<QaList />} />}
        />
        <Route
          path="/customerlist"
          element={<PrivateRoute children={<CustomersList />} />}
        />
        <Route
          path="/categorylist"
          element={<PrivateRoute children={<CategoryListScreen />} />}
        />
        <Route
          path="/addnewcategory"
          element={<PrivateRoute children={<AddNewCategory />} />}
        />
        <Route
          path="/addnewattribute"
          element={<PrivateRoute children={<AddNewAttribute />} />}
        />
        <Route
          path="/attributelist"
          element={<PrivateRoute children={<AttributeListScreen />} />}
        />
        <Route
          path="/productedit"
          element={<PrivateRoute children={<ProductEdit />} />}
        />
        <Route
          path="/rejectedproduct"
          element={<PrivateRoute children={<RejectedProduct />} />}
        />

        <Route
          path="/verifyvendor"
          element={<PrivateRoute children={<VendorVerification />} />}
        />
        <Route
          path="/checkout"
          element={<PrivateRoute children={<Checkout />} />}
        />
        <Route
          path="/orderview"
          element={<PrivateRoute children={<OrderView />} />}
        />
        <Route
          path="/websitebanners"
          element={<PrivateRoute children={<WebsiteBanners />} />}
        />
        <Route
          path="/addnewbanner"
          element={<PrivateRoute children={<AddNewBanner />} />}
        />
        <Route
          path="/addnewfit"
          element={<PrivateRoute children={<AddNewFit />} />}
        />
        <Route
          path="/addnewfabric"
          element={<PrivateRoute children={<AddNewFabric />} />}
        />
        <Route
          path="/fitlist"
          element={<PrivateRoute children={<FitListScreen />} />}
        />

        <Route
          path="/fabriclist"
          element={<PrivateRoute children={<FabricListScreen />} />}
        />
        <Route
          path="/coupons"
          element={<PrivateRoute children={<CouponsListScreen />} />}
        />
        <Route
          path="/addnewcoupons"
          element={<PrivateRoute children={<AddNewCoupons />} />}
        />
        <Route
          path="/blogs"
          element={<PrivateRoute children={<BlogsList />} />}
        />
        <Route
          path="/addnewblog"
          element={<PrivateRoute children={<AddNewBlog />} />}
        />

        <Route
          path="/editblog"
          element={<PrivateRoute children={<EditBlog />} />}
        />

        <Route
          path="/addnewblogcategory"
          element={<PrivateRoute children={<AddNewBlogCategory />} />}
        />

        <Route
          path="/blogcategorylist"
          element={<PrivateRoute children={<BlogCategoryList />} />}
        />

        <Route
          path="/addnewservice"
          element={<PrivateRoute children={<AddNewService />} />}
        />

        <Route
          path="/services"
          element={<PrivateRoute children={<ServiceList />} />}
        />

        <Route
          path="/editservice"
          element={<PrivateRoute children={<EditService />} />}
        />

        <Route
          path="/aboutus"
          element={<PrivateRoute children={<AboutUs />} />}
        />
        <Route
          path="/policies"
          element={<PrivateRoute children={<Policies />} />}
        />
        <Route
          path="/servicerequest"
          element={<PrivateRoute children={<ServiceRequestList />} />}
        />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
