import * as React from "react";

import { Box, Avatar, Button } from "@mui/material";

import { CameraAltSharp } from "@mui/icons-material";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function UpdateBanners({ item, cancel }) {
  const [imageUrl, setImageUrl] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const handleImage = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (imageUrl) => {
    await updateDoc(doc(db, "website_banners", item.id), {
      image: imageUrl,
      editedDate: new Date().toString(),
    });

    setLoading(false);
    window.location.reload()
  };

  React.useEffect(() => {
    if (cancel === false) {
      setImageUrl("");
    }
  }, [item, cancel]);

  const fileInput = React.useRef();

  const handleUploadImage = async () => {
    if (imageUrl !== "") {
      setLoading(true);
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "website_banner_images/" + file.name
      );

      await uploadBytes(mountainImagesRef, file).then(async (snapshot) => {
        await getDownloadURL(
          ref(storage, "website_banner_images/" + file.name)
        ).then((url) => handleSubmit(url));
      });
    } else {
      alert("Error");
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <input
          ref={fileInput}
          type="file"
          style={{ display: "none" }}
          onChange={handleImage}
        />

        <Button
          variant="contained"
          endIcon={<CameraAltSharp />}
          onClick={() => fileInput.current.click()}
          size="large"
          disabled={loading === false ? false : true}
        >
          Image
        </Button>

        <Avatar
          alt=""
          src={imageUrl !== "" ? imageUrl : item.image}
          variant="rounded"
          sx={{
            width: 56,
            height: 76,
            display: "table",
          }}
        />
        <Button
          variant="contained"
          size="large"
          onClick={handleUploadImage}
          disabled={loading === false ? false : true}
        >
          {loading === false ? "Submit" : "Please wait..."}
        </Button>
      </Box>
    </Box>
  );
}
