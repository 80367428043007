import * as React from "react";

import { Box, Avatar, TextField, Button } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { CameraAltSharp } from "@mui/icons-material";
import {
  getDocs,
  updateDoc,
  collection,
  doc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function UpdateCategory({ item, cancel }) {
  const theme = useTheme();

  const [parentCategory, setParentCategory] = React.useState("");
  const [imageUrl, setImageUrl] = React.useState("");
  const [categoryName, setCategoryName] = React.useState("");
  const [parentCategoryId, setParentCategoryId] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [attributes, setAttributes] = React.useState([]);
  const [names, setNames] = React.useState([]);
  const [parentCategoryData, setParentCategoryData] = React.useState([]);

  const handleChange = (event) => {
    setParentCategory(event.target.value);
  };

  const handleImage = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (imageUrl) => {
    await updateDoc(doc(db, "dashboard_categories", item.id), {
      name: categoryName,
      parent: parentCategory,
      image: imageUrl,
      editedDate: new Date().toString(),
      parentid: parentCategoryId,
      attributes: attributes,
    });

    setLoading(false);

    window.location.reload();
  };

  React.useEffect(() => {
    console.log({ item });
    getParentCategory();
    getAttributes();
    if (item.attributes !== undefined) {
      setCategoryName(item.name);
      setParentCategory(item.parent);
      setAttributes(item.attributes);
      setImageUrl(item.image);
    }
    if (cancel === false) {
      setImageUrl("");
    }
  }, [item, cancel]);

  const getParentCategory = async () => {
    await getDocs(collection(db, "dashboard_categories")).then(
      (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setParentCategoryData(newData);
      }
    );
  };

  const getAttributes = async () => {
    const q = query(
      collection(db, "dashboard_attributes"),
      where("status", "==", true)
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setNames(newData);
    });
  };

  const fileInput = React.useRef();

  const handleUploadImage = async () => {
    if (categoryName !== "" && imageUrl !== "") {
      setLoading(true);
      const storage = getStorage();

      if (file !== null) {
        const mountainImagesRef = ref(
          storage,
          "dashboard_category_images/" + file.name
        );

        await uploadBytes(mountainImagesRef, file).then(async (snapshot) => {
          await getDownloadURL(
            ref(storage, "dashboard_category_images/" + file.name)
          ).then((url) => handleSubmit(url));
        });
      } else {
        handleSubmit(imageUrl);
      }
    } else {
      alert("Error");
    }
  };

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setAttributes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          size="medium"
          onChange={(e) => setCategoryName(e.target.value)}
          value={categoryName}
        />

        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Select parent category(If any)
          </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={parentCategory}
            onChange={handleChange}
            autoWidth
            label="Select parent category(If any)"
            size="medium"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {parentCategoryData?.map((item, index) => (
              <MenuItem
                key={index}
                value={item.name}
                onClick={() => setParentCategoryId(item.id)}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Asign attribute</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={attributes}
            onChange={handleChange1}
            input={<OutlinedInput label="Asign attribute" />}
            MenuProps={MenuProps}
          >
            {names?.map((name, index) => (
              <MenuItem
                key={index}
                value={name.name}
                style={getStyles(name.name, attributes, theme)}
              >
                {name.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <input
          ref={fileInput}
          type="file"
          style={{ display: "none" }}
          onChange={handleImage}
        />

        <Button
          variant="contained"
          endIcon={<CameraAltSharp />}
          onClick={() => fileInput.current.click()}
          size="large"
          disabled={loading === false ? false : true}
        >
          Image
        </Button>

        <Avatar
          alt=""
          src={imageUrl !== "" ? imageUrl : item.image}
          variant="rounded"
          sx={{
            width: 56,
            height: 76,
            display: "table",
          }}
        />
        <Button
          variant="contained"
          size="large"
          onClick={handleUploadImage}
          disabled={loading === false ? false : true}
        >
          {loading === false ? "Submit" : "Please wait..."}
        </Button>
      </Box>
    </Box>
  );
}
