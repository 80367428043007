import * as React from "react";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddNewService() {
  const navigate = useNavigate();

  let userdetail = JSON.parse(localStorage.getItem("userdetail"));

  const [value, setValue] = React.useState(0);
  const [serviceHeading, setServiceHeading] = React.useState("");
  const [serviceShortDesc, setServiceShortDesc] = React.useState("");

  const [featuredImage, setFeaturedImage] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const handleChangeFeaturedImage = async (e) => {
    setLoading2(true);
    if (e.target.files[0]) {
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_services_images_" +
          userdetail[0]?.id +
          "/" +
          e.target.files[0]?.name
      );

      await uploadBytes(mountainImagesRef, e.target.files[0]).then(
        async (snapshot) => {
          await getDownloadURL(
            ref(
              storage,
              "dashboard_services_images_" +
                userdetail[0]?.id +
                "/" +
                e.target.files[0]?.name
            )
          ).then((url) => {
            setFeaturedImage(url);
          });
        }
      );
    }
    setLoading2(false);
  };

  const handleSubmit = async () => {
    if (
      serviceHeading !== "" &&
      serviceShortDesc !== "" &&
      featuredImage !== ""
    ) {
      setLoading(true);
      await addDoc(collection(db, "website_services"), {
        serviceHeading: serviceHeading,
        serviceShortDesc: serviceShortDesc,
        status: true,
        drafted: false,
        isNew: true,
        featuredImage: featuredImage,
        createdDate: new Date().toString(),
        vendorId: userdetail[0].id,
        vendor: [
          {
            name: userdetail[0].username,
            id: userdetail[0].id,
          },
        ],
        isFeatured: false,
      });
      navigate("/services");
    } else {
      setOpen2(true);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Add new service" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ width: "100%" }}>
          <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
            <Alert
              onClose={handleClose2}
              severity="error"
              sx={{ width: "100%" }}
            >
              Please fill all required fields!
            </Alert>
          </Snackbar>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Service data" {...a11yProps(0)} />
            </Tabs>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginRight: "2vw",
                }}
                onClick={handleSubmit}
                disabled={loading === true ? true : false}
              >
                {loading === false ? "Submit" : "Please wait..."}
              </Button>
              <Button
                size="large"
                variant="outlined"
                onClick={() => navigate("/services")}
              >
                Cancel
              </Button>
            </Box>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <TextField
              fullWidth
              label="Service Heading"
              onChange={(e) => setServiceHeading(e.target.value)}
              value={serviceHeading}
              required
            />
            <TextField
              sx={{ marginTop: 3 }}
              id="outlined-multiline-static"
              label="Service description"
              multiline
              rows={12}
              fullWidth
              onChange={(e) => setServiceShortDesc(e.target.value)}
              value={serviceShortDesc}
              required
            />

            <Typography sx={{ marginTop: 3 }}>
              Service featured image*
            </Typography>
            <Box
              sx={{
                borderBottom: 2,
                paddingBottom: 3,
                borderBottomColor: "darkgray",
                border: 1,
                borderColor: "#C4C4C4",

                padding: 2,
              }}
            >
              <Paper elevation={0}>
                <input
                  accept="image/*"
                  name="featuredthumb"
                  id="featuredthumb"
                  type="file"
                  onChange={(e) => handleChangeFeaturedImage(e)}
                  style={{
                    display: "none",
                    width: "106px",
                    height: "106px",
                  }}
                />
                {loading2 === true ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <label htmlFor="featuredthumb">
                    {featuredImage !== "" ? (
                      <img
                        alt=""
                        src={featuredImage}
                        style={{
                          width: "106px",
                          height: "106px",
                        }}
                      />
                    ) : (
                      <Button
                        component="span"
                        sx={{
                          backgroundColor: "#CEF4FF",
                          width: "125px",
                          height: "125px",
                          border: "dotted",
                          borderWidth: "2px",
                          borderColor: "blue",
                        }}
                      >
                        <Add color="primary" />
                      </Button>
                    )}
                  </label>
                )}
              </Paper>
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
}
