import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import Skeleton from "@mui/material/Skeleton";
import { visuallyHidden } from "@mui/utils";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Button, ListItemText, Zoom } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Close, Edit, Search } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import {
  getDocs,
  collection,
  query,
  updateDoc,
  doc,
  where,
  addDoc,
} from "firebase/firestore";

import { db } from "../../firebase";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "vendors",
    numeric: true,
    disablePadding: false,
    label: "Assigned vendors",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Created date",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const theme = useTheme();
  const { numSelected } = props;
  const [open, setOpen] = React.useState(false);
  const [assinedVendorsId, setAssignedVendorsId] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [vendorList, setVendorList] = React.useState([]);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAssignedVendorsId(typeof value === "string" ? value.split(",") : value);
  };

  React.useEffect(() => {
    if (open === true) {
      getUsers();
    }
  }, [open]);

  const getUsers = async () => {
    setLoading(true);
    const q = query(
      collection(db, "dashboard_users"),
      where("role", "==", "vendor")
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setVendorList(newData);
      setLoading(false);
    });
  };

  const handleSubmit = async () => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (
      name !== "" &&
      email.match(validRegex) &&
      phone !== "" &&
      password !== "" &&
      assinedVendorsId.length > 0
    ) {
      const q = query(
        collection(db, "dashboard_users"),
        where("email", "==", email)
      );

      await getDocs(q).then(async (querySnapshot) => {
        setLoading(false);
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        if (newData.length !== 0) {
          alert("Email already exist");
          setLoading(false);
        } else {
          setLoading(true);
          await addDoc(collection(db, "dashboard_users"), {
            assignedVendor: assinedVendorsId,
            createdDate: new Date().toString(),
            email: email,
            name: name,
            password: password,
            phone: phone,
            role: "qa",
            status: true,
            username: email,
          });
          setLoading(false);
          setOpen(false);
          window.location.reload();
        }
      });
    } else {
      alert("Fill all required field");
    }
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Typography
            sx={{ flex: "1 1 20%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Users
          </Typography>
          <Box sx={{ padding: "1em" }}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Search"
              variant="outlined"
              placeholder="Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{ marginRight: "1em", width: "24em" }}
              onChange={(e) => props.setSearchText(e.target.value)}
              value={props.searchText}
            />
            <Button
              variant="contained"
              size="medium"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              Add New
            </Button>
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Creating new QA"}
            </DialogTitle>
            <DialogContent>
              <TextField
                label="QA Name*"
                fullWidth
                sx={{ marginTop: 2 }}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="QA Email*"
                fullWidth
                sx={{ marginTop: 2 }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="QA Phone*"
                fullWidth
                type="number"
                sx={{ marginTop: 2 }}
                onChange={(e) =>
                  e.target.value.toString().length <= 10
                    ? setPhone(e.target.value)
                    : null
                }
                value={phone}
              />
              <TextField
                label="New password*"
                fullWidth
                sx={{ marginTop: 2 }}
                onChange={(e) => setPassword(e.target.value)}
              />

              <FormControl sx={{ marginTop: 2 }} fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Assign vendors*
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={assinedVendorsId}
                  onChange={handleChange}
                  input={<OutlinedInput label="Assign vendors*" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {vendorList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.name}
                      style={getStyles(item.name, assinedVendorsId, theme)}
                      disabled={
                        assinedVendorsId.length === 3 &&
                        assinedVendorsId.find((e) => e === item.name) ===
                          undefined
                          ? true
                          : false
                      }
                    >
                      <Checkbox
                        checked={assinedVendorsId.indexOf(item.name) > -1}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                disabled={loading === true ? true : false}
              >
                {loading === true ? "Please wait..." : "Submit"}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function QaList() {
  const theme = useTheme();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [assinedVendorsId, setAssignedVendorsId] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [vendorList, setVendorList] = React.useState([]);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [id, setId] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (
      name !== "" &&
      email.match(validRegex) &&
      phone !== "" &&
      password !== "" &&
      assinedVendorsId.length > 0
    ) {
      await updateDoc(
        doc(db, "dashboard_users", id),

        {
          assignedVendor: assinedVendorsId,
          editedDate: new Date().toString(),
          email: email,
          name: name,
          password: password,
          phone: phone,
        }
      );
      window.location.reload();
    } else {
      alert("Fill all required field");
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAssignedVendorsId(typeof value === "string" ? value.split(",") : value);
  };

  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = usersList.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(usersList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [usersList, order, orderBy, page, rowsPerPage]
  );

  React.useEffect(() => {
    if (searchText !== "") {
      handleSearch();
    } else {
      getUsers();
    }
  }, [searchText]);

  const handleSearch = async () => {
    setLoading(true);

    let list = usersList.filter((e) =>
      e.name.toLowerCase().includes(searchText)
    );

    setUsersList(list);
    setLoading(false);
  };

  const getUsers = async () => {
    setLoading(true);
    const q = query(
      collection(db, "dashboard_users"),
      where("role", "==", "qa")
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setUsersList(newData);
      setLoading(false);
    });
  };
  const updateProductStatus = async (id, status) => {
    await updateDoc(
      doc(db, "dashboard_users", id),

      {
        status: status,
        verifiedDate: new Date().toString(),
      }
    );
    window.location.reload();
  };

  const handleEdit = (row) => {
    setOpen(true);
    setName(row.name);
    setEmail(row.email);
    setPhone(row.phone);
    setPassword(row.password);
    setAssignedVendorsId(row.assignedVendor);
    setId(row.id);
    getVendors();
  };

  const getVendors = async () => {
    setLoading(true);
    const q = query(
      collection(db, "dashboard_users"),
      where("role", "==", "vendor")
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setVendorList(newData);
      setLoading(false);
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="QA list" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              searchText={searchText}
              setSearchText={setSearchText}
            />
            <TableContainer>
              {loading === true ? (
                <Box sx={{ width: "100%" }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              ) : (
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={usersList.length}
                  />

                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              onClick={(event) => handleClick(event, row.name)}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {row.name}
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            {row.assignedVendor.map((e, index) => (
                              <Typography key={index}>{e}</Typography>
                            ))}
                          </TableCell>
                          <TableCell align="right">
                            <Typography>{row.email}</Typography>
                          </TableCell>
                          <TableCell align="right">{row.phone}</TableCell>
                          <TableCell align="right">{row.role}</TableCell>
                          <TableCell align="right">
                            {row.createdDate.slice(0, 16)}
                          </TableCell>
                          <TableCell align="right">
                            <Box>
                              <Tooltip
                                title={
                                  row.status === true ? "Inactive" : "Active"
                                }
                                TransitionComponent={Zoom}
                                placement="top"
                              >
                                <Switch
                                  checked={row.status === true ? true : false}
                                  color="success"
                                  sx={{
                                    "&.MuiSwitch-root .MuiSwitch-switchBase": {
                                      color: "red",
                                    },

                                    "&.MuiSwitch-root .Mui-checked": {
                                      color: "green",
                                    },
                                  }}
                                  onChange={() =>
                                    updateProductStatus(
                                      row.id,
                                      row.status === true ? false : true
                                    )
                                  }
                                />
                              </Tooltip>
                              <Tooltip
                                title={
                                  row.status === true ? "Inactive" : "Active"
                                }
                                TransitionComponent={Zoom}
                                placement="top"
                              >
                                <IconButton onClick={() => handleEdit(row)}>
                                  <Edit color="primary" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Edit QA"}
                    </DialogTitle>
                    <DialogContent>
                      <TextField
                        label="QA Name*"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                      <TextField
                        label="QA Email*"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                      <TextField
                        label="QA Phone*"
                        fullWidth
                        type="number"
                        sx={{ marginTop: 2 }}
                        onChange={(e) =>
                          e.target.value.toString().length <= 10
                            ? setPhone(e.target.value)
                            : null
                        }
                        value={phone}
                      />
                      <TextField
                        label="New password*"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />

                      <FormControl sx={{ marginTop: 2 }} fullWidth>
                        <InputLabel id="demo-multiple-name-label">
                          Assign vendors*
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          multiple
                          value={assinedVendorsId}
                          onChange={handleChange}
                          input={<OutlinedInput label="Assign vendors*" />}
                          MenuProps={MenuProps}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {vendorList.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={item.id}
                              style={getStyles(
                                item.id,
                                assinedVendorsId,
                                theme
                              )}
                              disabled={
                                assinedVendorsId.length === 3 &&
                                assinedVendorsId.find(
                                  (e) => e === item.id
                                ) === undefined
                                  ? true
                                  : false
                              }
                            >
                              <Checkbox
                                checked={
                                  assinedVendorsId.indexOf(item.id) > -1
                                }
                              />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={loading === true ? true : false}
                      >
                        {loading === true ? "Please wait..." : "Submit"}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={usersList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
