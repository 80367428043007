import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import Skeleton from "@mui/material/Skeleton";
import { visuallyHidden } from "@mui/utils";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import { Button, Zoom } from "@mui/material";
import TextField from "@mui/material/TextField";
import { RemoveRedEye, Search } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import {
  getDocs,
  collection,
  query,
  updateDoc,
  doc,
  where,
} from "firebase/firestore";

import { db } from "../../firebase";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "code",
    numeric: true,
    disablePadding: false,
    label: "Style code",
  },
  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Thumbnail",
  },
  {
    id: "stock",
    numeric: true,
    disablePadding: false,
    label: "Stock",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Created date",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  let userdetail = JSON.parse(localStorage.getItem("userdetail"));

  const navigate = useNavigate();

  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Typography
            sx={{ flex: "1 1 20%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Products
          </Typography>
          <Box sx={{ padding: "1em" }}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Search"
              variant="outlined"
              placeholder="Product name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{ marginRight: "1em", width: "24em" }}
              onChange={(e) => props.setSearchText(e.target.value)}
              value={props.searchText}
            />
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker slotProps={{ textField: { size: "small" } }} />
            </LocalizationProvider> */}
          </Box>
          {userdetail[0]?.role !== "qa" ? (
            <Button
              variant="contained"
              size="medium"
              color="success"
              startIcon={<AddIcon />}
              onClick={() => navigate("/addnewproduct")}
            >
              Add New
            </Button>
          ) : null}
        </>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function QaProduct() {
  let userdetail = JSON.parse(localStorage.getItem("userdetail"));

  const navigate = useNavigate();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [productList, setProductList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [searchText, setSearchText] = React.useState("");

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = productList.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productList.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(productList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [productList, order, orderBy, page, rowsPerPage]
  );

  React.useEffect(() => {
    if (searchText !== "") {
      handleSearch();
    } else {
      getProducts();
    }
  }, [searchText]);

  const handleSearch = async () => {
    setLoading(true);

    let list = productList.filter((e) =>
      e.productName.toLowerCase().includes(searchText)
    );

    setProductList(list);
    setLoading(false);
  };

  const getProducts = async () => {
    setLoading(true);

    if (userdetail[0]?.role === "qa") {
      const updatedData = await Promise.all(
        userdetail[0]?.assignedVendor.map(async (e, index) => {
          const q = query(
            collection(db, "dashboard_product"),
            where("vendorId", "==", userdetail[0]?.assignedVendor[index]),
            where("qaChecked", "==", false),
            where("status", "==", true)
          );
          const querySnapshot = await getDocs(q);

          const newData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          return newData;
        })
      );

      setProductList(updatedData.flat());

      setLoading(false);
    } else {
      if (userdetail[0]?.role === "admin") {
        const q = query(
          collection(db, "dashboard_product"),
          where("qaChecked", "==", false),
          where("status", "==", true)
        );
        await getDocs(q).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          setProductList(newData);

          setLoading(false);
        });
      } else {
        const q = query(
          collection(db, "dashboard_product"),
          where("vendorId", "==", userdetail[0]?.id),
          where("qaChecked", "==", false),
          where("status", "==", true)
        );
        await getDocs(q).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          setProductList(newData);

          setLoading(false);
        });
      }
    }
  };

  const updateProductStatus = async (id, status, approved) => {
    await updateDoc(doc(db, "dashboard_product", id), {
      qaChecked: status,
      approvedBy: [...approved, { datte: new Date(), id: userdetail[0]?.id }],
      isNew: false,
    });
    window.location.reload();
  };

  const handleEditProduct = (row) => {
    navigate("/productedit", { state: { data: row } });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="QA product" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              searchText={searchText}
              setSearchText={setSearchText}
            />
            <TableContainer>
              {loading === true ? (
                <Box sx={{ width: "100%" }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              ) : (
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={productList.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.productName);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              onClick={(event) =>
                                handleClick(event, row.productName)
                              }
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={{ width: 200 }}
                          >
                            {row.productName}
                          </TableCell>
                          <TableCell align="right">
                            {row.productStyleCode}
                          </TableCell>
                          <TableCell align="right">
                            <Avatar
                              alt=""
                              src={row?.variants !== undefined ? row?.variants[0]?.thumb : null}
                              variant="rounded"
                              sx={{
                                width: 56,
                                height: 76,
                                display: "-webkit-inline-flex",
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {row.variants?.map((s) => s.value)?.join(", ")} :{" "}
                            {row.variants?.map((s) => s.qty)?.join(", ")}
                          </TableCell>
                          <TableCell align="right">28-07-23</TableCell>
                          <TableCell align="right">
                            <Box>
                              {userdetail[0]?.role === "qa" ||
                              userdetail[0]?.role === "admin" ? (
                                <Tooltip
                                  title="Publish"
                                  TransitionComponent={Zoom}
                                  placement="top"
                                >
                                  <Switch
                                    defaultChecked={
                                      row.qaChecked === true ? true : false
                                    }
                                    color="success"
                                    sx={{
                                      "&.MuiSwitch-root .MuiSwitch-switchBase":
                                        {
                                          color: "red",
                                        },

                                      "&.MuiSwitch-root .Mui-checked": {
                                        color: "green",
                                      },
                                    }}
                                    onChange={() =>
                                      updateProductStatus(
                                        row.id,
                                        row.qaChecked === true ? false : true,
                                        row.approvedBy
                                      )
                                    }
                                  />
                                </Tooltip>
                              ) : null}
                              <Tooltip
                                title="View"
                                TransitionComponent={Zoom}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => handleEditProduct(row)}
                                >
                                  <RemoveRedEye color="primary" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={productList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
