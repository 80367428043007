import * as React from "react";

import Box from "@mui/material/Box";
import { Autocomplete, Chip, IconButton, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";
import { Add, Delete } from "@mui/icons-material";
import { getDocs, collection, query, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddNewBlog() {
  const navigate = useNavigate();

  let userdetail = JSON.parse(localStorage.getItem("userdetail"));

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(true);
  const [addNewProduct, setAddNewProduct] = React.useState(false);
  const [blogHeading, setBlogHeading] = React.useState("");
  const [blogShortDesc, setBlogShortDesc] = React.useState("");

  const [featuredImage, setFeaturedImage] = React.useState("");

  const [categoryData, setCategoryData] = React.useState([]);
  const [tags] = React.useState(["Tags"]);
  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);

  const [tagsValues, setTagsValues] = React.useState([]);
  const [indexThumb, setIndexThumb] = React.useState(0);
  const [category, setCategory] = React.useState({});

  const [longDesc, setLongDesc] = React.useState([
    {
      title: "",
      descthumb: "",
      desc: "",
    },
  ]);

  const [OtherDesc, setOtherDesc] = React.useState([
    {
      mainheading: "",
      maindesc: "",
    },
  ]);

  const [OtherDesc1, setOtherDesc1] = React.useState([
    {
      title: "",
      descthumb: "",
      desc: "",
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  React.useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setLoading(true);
    const q = query(collection(db, "blogs_category"));
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setCategoryData(newData);
      setLoading(false);
    });
  };

  const handleSelectMainCategory = (e) => {
    setOpen(false);
    setAddNewProduct(true);
    setCategory(e);
  };

  const handleAddLongDesc = () => {
    setLongDesc([
      ...longDesc,
      {
        title: "",
        descthumb: "",
        desc: "",
      },
    ]);
  };

  const handleAddOtherDesc = () => {
    setOtherDesc([
      ...OtherDesc,
      {
        mainheading: "",
        maindesc: "",
      },
    ]);
  };

  const handleAddOtherDesc1 = () => {
    setOtherDesc1([
      ...OtherDesc1,
      {
        title: "",
        descthumb: "",
        desc: "",
      },
    ]);
  };

  const handleChangeOtherTitle1 = (e, idx) => {
    const { name, value } = e.target;

    const list = [...OtherDesc1];

    list[idx][name] = value;

    setOtherDesc1(list);
  };

  const handleChangeOtherDesc1 = (e, idx) => {
    const { name, value } = e.target;

    const list = [...OtherDesc1];

    list[idx][name] = value;

    setOtherDesc1(list);
  };

  const handleChangeDescThumnail = async (e) => {
    setLoading1(true);
    if (e.target.files[0]) {
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_blog_images_" +
          userdetail[0]?.id +
          "/" +
          e.target.files[0]?.name
      );

      await uploadBytes(mountainImagesRef, e.target.files[0]).then(
        async (snapshot) => {
          await getDownloadURL(
            ref(
              storage,
              "dashboard_blog_images_" +
                userdetail[0]?.id +
                "/" +
                e.target.files[0]?.name
            )
          ).then((url) => {
            const { name } = e.target;

            const list = [...longDesc];

            list[indexThumb][name] = url;
            setLongDesc(list);
          });
        }
      );
    }
    setLoading1(false);
  };

  const handleChangeFeaturedImage = async (e) => {
    setLoading2(true);
    if (e.target.files[0]) {
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_blogs_images_" +
          userdetail[0]?.id +
          "/" +
          e.target.files[0]?.name
      );

      await uploadBytes(mountainImagesRef, e.target.files[0]).then(
        async (snapshot) => {
          await getDownloadURL(
            ref(
              storage,
              "dashboard_blogs_images_" +
                userdetail[0]?.id +
                "/" +
                e.target.files[0]?.name
            )
          ).then((url) => {
            setFeaturedImage(url);
          });
        }
      );
    }
    setLoading2(false);
  };

  const handleChangeTitle = (e, idx) => {
    const { name, value } = e.target;

    const list = [...longDesc];

    list[idx][name] = value;

    setLongDesc(list);
  };

  const handleChangeOtherTitle = (e, idx) => {
    const { name, value } = e.target;

    const list = [...OtherDesc];

    list[idx][name] = value;

    setOtherDesc(list);
  };

  const handleChangeDesc = (e, idx) => {
    const { name, value } = e.target;

    const list = [...longDesc];

    list[idx][name] = value;

    setLongDesc(list);
  };

  const handleChangeOtherDesc = (e, idx) => {
    const { name, value } = e.target;

    const list = [...OtherDesc];

    list[idx][name] = value;

    setOtherDesc(list);
  };

  const removeDesc = (ii) => {
    const list = [...longDesc];

    list.splice(ii, 1);

    setLongDesc(list);
  };

  const removeOtherDesc = (ii) => {
    const list = [...OtherDesc];

    list.splice(ii, 1);

    setOtherDesc(list);
  };

  const removeOtherDesc1 = (ii) => {
    const list = [...OtherDesc1];

    list.splice(ii, 1);

    setOtherDesc1(list);
  };

  const handleSubmit = async () => {
    if (blogHeading !== "" && blogShortDesc !== "" && featuredImage !== "") {
      setLoading(true);
      await addDoc(collection(db, "website_blogs"), {
        blogHeading: blogHeading,
        blogShortDesc: blogShortDesc,
        blogLongDesc: longDesc,
        tags: tagsValues,
        category: category,
        status: true,
        drafted: false,
        isNew: true,
        featuredImage: featuredImage,
        createdDate: new Date().toString(),
        vendorId: userdetail[0].id,
        vendor: [
          {
            name: userdetail[0].username,
            id: userdetail[0].id,
          },
        ],
        isFeatured: false,
      });

      navigate("/blogs");
    } else {
      setOpen2(true);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Add new blog" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ width: "100%" }}>
          <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
            <Alert
              onClose={handleClose2}
              severity="error"
              sx={{ width: "100%" }}
            >
              Please fill all required fields!
            </Alert>
          </Snackbar>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Blog data" {...a11yProps(0)} />
            </Tabs>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginRight: "2vw",
                }}
                onClick={handleSubmit}
                disabled={loading === true ? true : false}
              >
                {loading === false ? "Submit" : "Please wait..."}
              </Button>
              <Button
                size="large"
                variant="outlined"
                onClick={() => navigate("/blogs")}
              >
                Cancel
              </Button>
            </Box>
          </Box>

          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Select blog category"}
            </DialogTitle>
            <DialogContent>
              {loading === true ? (
                <Box sx={{ width: "100%" }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              ) : (
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  {categoryData.map((item, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      onClick={() => handleSelectMainCategory(item.name)}
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar src={item.image} alt="" />
                        </ListItemAvatar>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>

          {addNewProduct === true ? (
            <>
              <CustomTabPanel value={value} index={0}>
                <TextField
                  fullWidth
                  label="Blog Heading"
                  onChange={(e) => setBlogHeading(e.target.value)}
                  value={blogHeading}
                  required
                />
                <TextField
                  sx={{ marginTop: 3 }}
                  id="outlined-multiline-static"
                  label="Blog description"
                  multiline
                  rows={12}
                  fullWidth
                  onChange={(e) => setBlogShortDesc(e.target.value)}
                  value={blogShortDesc}
                  required
                />

                <Typography sx={{ marginTop: 3 }}>
                  Blog more description
                </Typography>
                {longDesc.map((item, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      borderBottom: 2,
                      paddingBottom: 3,
                      borderBottomColor: "darkgray",
                      border: 1,
                      borderColor: "#C4C4C4",

                      padding: 2,
                    }}
                  >
                    <Paper elevation={0}>
                      {longDesc.length > 1 ? (
                        <IconButton onClick={() => removeDesc(idx)}>
                          <Delete color="error" />
                        </IconButton>
                      ) : null}
                      <TextField
                        id="title"
                        name="title"
                        value={item.title}
                        label="Title"
                        variant="outlined"
                        onChange={(e) => handleChangeTitle(e, idx)}
                        fullWidth
                      />

                      <TextField
                        sx={{ marginTop: 3, marginBottom: 3 }}
                        id="desc"
                        label="Description"
                        name="desc"
                        multiline
                        rows={5}
                        fullWidth
                        onChange={(e) => handleChangeDesc(e, idx)}
                        value={item.desc}
                      />
                      <input
                        accept="image/*"
                        name="descthumb"
                        id="descthumb"
                        type="file"
                        onChange={(e) => handleChangeDescThumnail(e)}
                        style={{
                          display: "none",
                          width: "106px",
                          height: "106px",
                        }}
                      />
                      {loading1 === true && indexThumb === idx ? (
                        <Box sx={{ display: "flex" }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <label htmlFor="descthumb">
                          {item.descthumb !== "" ? (
                            <img
                              alt=""
                              src={item.descthumb}
                              style={{
                                width: "106px",
                                height: "106px",
                              }}
                            />
                          ) : (
                            <Button
                              component="span"
                              sx={{
                                backgroundColor: "#CEF4FF",
                                width: "125px",
                                height: "125px",
                                border: "dotted",
                                borderWidth: "2px",
                                borderColor: "blue",
                              }}
                              onClick={() => setIndexThumb(idx)}
                            >
                              <Add color="primary" />
                            </Button>
                          )}
                        </label>
                      )}
                      <Typography>Thumbnail image</Typography>
                    </Paper>
                  </Box>
                ))}

                <Box style={{ display: "flex", marginTop: "1vw" }}>
                  <Button
                    startIcon={
                      <Add
                        color="primary"
                        sx={{ backgroundColor: "#CEF4FF" }}
                      />
                    }
                    variant="text"
                    color="primary"
                    onClick={handleAddLongDesc}
                  >
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        textTransform: "none",
                      }}
                    >
                      Add more
                    </Typography>
                  </Button>
                </Box>

                {/* <Typography sx={{ marginTop: 3 }}>
                  Blog other section data
                </Typography>
                {OtherDesc.map((item, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      borderBottom: 2,
                      paddingBottom: 3,
                      borderBottomColor: "darkgray",
                      border: 1,
                      borderColor: "#C4C4C4",

                      padding: 2,
                    }}
                  >
                    <Paper elevation={0}>
                      {OtherDesc.length > 1 ? (
                        <IconButton onClick={() => removeOtherDesc(idx)}>
                          <Delete color="error" />
                        </IconButton>
                      ) : null}
                      <TextField
                        id="mainheading"
                        name="mainheading"
                        value={item.mainheading}
                        label="Main Title"
                        variant="outlined"
                        onChange={(e) => handleChangeOtherTitle(e, idx)}
                        fullWidth
                      />

                      <TextField
                        sx={{ marginTop: 3, marginBottom: 3 }}
                        id="maindesc"
                        label="Main Description"
                        name="maindesc"
                        multiline
                        rows={5}
                        fullWidth
                        onChange={(e) => handleChangeOtherDesc(e, idx)}
                        value={item.maindesc}
                      />
                      {OtherDesc1.map((itemm, idxx) => (
                        <>
                          {OtherDesc1.length > 1 ? (
                            <IconButton onClick={() => removeOtherDesc1(idxx)}>
                              <Delete color="error" />
                            </IconButton>
                          ) : null}
                          <TextField
                            id="title"
                            name="title"
                            value={itemm.title}
                            label="Title"
                            variant="outlined"
                            onChange={(e) => handleChangeOtherTitle1(e, idxx)}
                            fullWidth
                          />

                          <TextField
                            sx={{ marginTop: 3, marginBottom: 3 }}
                            id="desc"
                            label="Description"
                            name="desc"
                            multiline
                            rows={5}
                            fullWidth
                            onChange={(e) => handleChangeOtherDesc1(e, idxx)}
                            value={itemm.desc}
                          />
                          <input
                            accept="image/*"
                            name="descthumb"
                            id="descthumb"
                            type="file"
                            onChange={(e) => handleChangeDescThumnail(e)}
                            style={{
                              display: "none",
                              width: "106px",
                              height: "106px",
                            }}
                          />
                          {loading1 === true && indexThumb === idxx ? (
                            <Box sx={{ display: "flex" }}>
                              <CircularProgress />
                            </Box>
                          ) : (
                            <label htmlFor="descthumb">
                              {item.descthumb !== "" ? (
                                <img
                                  alt=""
                                  src={item.descthumb}
                                  style={{
                                    width: "106px",
                                    height: "106px",
                                  }}
                                />
                              ) : (
                                <Button
                                  component="span"
                                  sx={{
                                    backgroundColor: "#CEF4FF",
                                    width: "125px",
                                    height: "125px",
                                    border: "dotted",
                                    borderWidth: "2px",
                                    borderColor: "blue",
                                  }}
                                  onClick={() => setIndexThumb(idxx)}
                                >
                                  <Add color="primary" />
                                </Button>
                              )}
                            </label>
                          )}
                          <Typography>Thumbnail image</Typography>
                        </>
                      ))}
                      <Box style={{ display: "flex", marginTop: "1vw" }}>
                        <Button
                          startIcon={
                            <Add
                              color="primary"
                              sx={{ backgroundColor: "#CEF4FF" }}
                            />
                          }
                          variant="text"
                          color="primary"
                          onClick={handleAddOtherDesc1}
                        >
                          <Typography
                            sx={{
                              textDecoration: "underline",
                              textTransform: "none",
                            }}
                          >
                            Add more
                          </Typography>
                        </Button>
                      </Box>
                    </Paper>
                  </Box>
                ))}

                <Box style={{ display: "flex", marginTop: "1vw" }}>
                  <Button
                    startIcon={
                      <Add
                        color="primary"
                        sx={{ backgroundColor: "#CEF4FF" }}
                      />
                    }
                    variant="text"
                    color="primary"
                    onClick={handleAddOtherDesc}
                  >
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        textTransform: "none",
                      }}
                    >
                      Add more
                    </Typography>
                  </Button>
                </Box> */}
                <Typography sx={{ marginTop: 3 }}>
                  Blog featured image*
                </Typography>
                <Box
                  sx={{
                    borderBottom: 2,
                    paddingBottom: 3,
                    borderBottomColor: "darkgray",
                    border: 1,
                    borderColor: "#C4C4C4",

                    padding: 2,
                  }}
                >
                  <Paper elevation={0}>
                    <input
                      accept="image/*"
                      name="featuredthumb"
                      id="featuredthumb"
                      type="file"
                      onChange={(e) => handleChangeFeaturedImage(e)}
                      style={{
                        display: "none",
                        width: "106px",
                        height: "106px",
                      }}
                    />
                    {loading2 === true ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <label htmlFor="featuredthumb">
                        {featuredImage !== "" ? (
                          <img
                            alt=""
                            src={featuredImage}
                            style={{
                              width: "106px",
                              height: "106px",
                            }}
                          />
                        ) : (
                          <Button
                            component="span"
                            sx={{
                              backgroundColor: "#CEF4FF",
                              width: "125px",
                              height: "125px",
                              border: "dotted",
                              borderWidth: "2px",
                              borderColor: "blue",
                            }}
                          >
                            <Add color="primary" />
                          </Button>
                        )}
                      </label>
                    )}
                  </Paper>
                </Box>

                {tags.map((item, index) => (
                  <Autocomplete
                    key={index}
                    fullWidth
                    label="Tags"
                    multiple
                    onChange={(e, value) => {
                      setTagsValues((vals) => ({
                        ...vals,
                        [item]: { value, typeName: item },
                      }));
                    }}
                    // disabled={editing}
                    value={tagsValues[item]?.value ?? []}
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{ marginTop: 3 }}
                        id="outlined-basic"
                        label={item}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                ))}
              </CustomTabPanel>
            </>
          ) : (
            <Typography variant="h5">
              Ooops no any category selected!
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
