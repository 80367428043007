import * as React from "react";

import { Box, Avatar, Button } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import { CameraAltSharp } from "@mui/icons-material";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const parentCategoryData = [
  {
    name: "Main slider",
  },
  {
    name: "Best selling",
  },
  {
    name: "Featured",
  },
  {
    name: "Featured slider",
  },
  {
    name: "Women collection",
  },
  {
    name: "Men collection",
  },
  {
    name: "New arrival",
  },
  {
    name: "Kids",
  },
];

export default function AddNewBanner() {
  const [bannerPlace, setBannerPlace] = React.useState("");
  const [bannerType, setBannerType] = React.useState("");

  const [imageUrl, setImageUrl] = React.useState("");
  const [file, setFile] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const handleChange = (event) => {
    setBannerPlace(event.target.value);
  };

  const handleChangeType = (event) => {
    setBannerType(event.target.value);
  };

  const handleImage = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (imageUrl) => {
    await addDoc(collection(db, "website_banners"), {
      place: bannerPlace,
      type: bannerType,
      image: imageUrl,
      status: false,
      createdDate: new Date().toString(),
    });

    window.location.reload();

    setLoading(false);
  };

  const fileInput = React.useRef();

  const handleUploadImage = async () => {
    if (bannerPlace !== "" && imageUrl !== "") {
      setLoading(true);
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "website_banner_images/" + file.name
      );

      await uploadBytes(mountainImagesRef, file).then(async (snapshot) => {
        await getDownloadURL(
          ref(storage, "website_banner_images/" + file.name)
        ).then((url) => handleSubmit(url));
      });
    } else {
      alert("Error");
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Add new banner" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Select place
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={bannerPlace}
              onChange={handleChange}
              autoWidth
              label="Select place"
              size="medium"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {parentCategoryData?.map((item, index) => (
                <MenuItem key={index} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Select type
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={bannerType}
              onChange={handleChangeType}
              autoWidth
              label="Select type"
              size="medium"
            >
              <MenuItem value="desktop">Desktop</MenuItem>
              <MenuItem value="mobile">Mobile</MenuItem>
            </Select>
          </FormControl>

          <input
            ref={fileInput}
            type="file"
            style={{ display: "none" }}
            onChange={handleImage}
          />

          <Button
            variant="contained"
            endIcon={<CameraAltSharp />}
            onClick={() => fileInput.current.click()}
            size="large"
            disabled={loading === false ? false : true}
          >
            Image
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleUploadImage}
            disabled={loading === false ? false : true}
          >
            {loading === false ? "Submit" : "Please wait..."}
          </Button>

          <Avatar
            alt=""
            src={imageUrl}
            variant="rounded"
            sx={{
              width: 56,
              height: 76,
              display: "table",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
