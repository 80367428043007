import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";

import {
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  IconButton,
  Divider,
  Typography,
  CssBaseline,
  List,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Box,
  Avatar,
} from "@mui/material";
import userImage from "../../assets/img/user.jpg";

import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import {
  Home,
  Inventory,
  KeyboardArrowDown,
  MenuBookRounded,
  ShoppingCart,
  VerifiedUser,
  KeyboardArrowUp,
  Category,
  EditAttributes,
  NotInterested,
  Collections,
  Accessibility,
  MergeType,
  LocalOffer,
  PostAdd,
  Handshake,
  Person3,
  Policy,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MenuBar({ menuBarName }) {
  let userDetail = JSON.parse(localStorage.getItem("userdetail"));

  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CssBaseline />

      <AppBar
        position="fixed"
        open={open}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          paddingRight: 5,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {menuBarName}
          </Typography>
        </Toolbar>
        <Box>
          <Button
            startIcon={open1 ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            color="inherit"
            id="basic-button"
            aria-controls={open1 ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick}
          >
            {userDetail[0]?.username}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open1}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>Profile</MenuItem>

            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>

          {/* <Badge badgeContent={4} color="success">
            <NotificationAdd color="inherit" />
          </Badge> */}
        </Box>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Avatar alt="Remy Sharp" src={userImage} sx={{ marginRight: 3 }} />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ textTransform: "capitalize", marginRight: 3 }}
          >
            {userDetail[0]?.role}
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              selected={menuBarName === "Dashboard" ? true : false}
              onClick={() => navigate("/dashboard")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Home />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            {userDetail[0]?.role !== "qa" ? (
              <>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "Product list" ? true : false}
                  onClick={() => navigate("/productlist")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ShoppingCart />
                  </ListItemIcon>
                  <ListItemText
                    primary="Products List"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Add new product" ? true : false}
                  onClick={() => navigate("/addnewproduct")}
                >
                  <ListItemText
                    primary="Add New Products"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Published product" ? true : false}
                  onClick={() => navigate("/publishedproduct")}
                >
                  <ListItemText
                    primary="Published Products"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Drafted product" ? true : false}
                  onClick={() => navigate("/draftedproduct")}
                >
                  <ListItemText
                    primary="Drafted Products"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </>
            ) : null}

            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: userDetail[0].role === "qa" ? 2.5 : 8.5,
              }}
              selected={menuBarName === "QA product" ? true : false}
              onClick={() => navigate("/qaproduct")}
            >
              {userDetail[0].role === "qa" ? (
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ShoppingCart />
                </ListItemIcon>
              ) : null}
              <ListItemText
                primary="In QA Products"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>

            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: userDetail[0].role === "qa" ? 2.5 : 8.5,
              }}
              selected={menuBarName === "Rejected product" ? true : false}
              onClick={() => navigate("/rejectedproduct")}
            >
              {userDetail[0].role === "qa" ? (
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <NotInterested />
                </ListItemIcon>
              ) : null}
              <ListItemText
                primary="Rejected Products"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          {userDetail[0].role !== "qa" ? (
            <>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "Order list" ? true : false}
                  onClick={() => navigate("/orderlist")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <MenuBookRounded />
                  </ListItemIcon>
                  <ListItemText
                    primary="Orders List"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Create order" ? true : false}
                  onClick={() => navigate("/checkout")}
                >
                  <ListItemText
                    primary="Create Order"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "New orders" ? true : false}
                  onClick={() => navigate("/neworders")}
                >
                  <ListItemText primary="New" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Completed orders" ? true : false}
                  onClick={() => navigate("/completedorders")}
                >
                  <ListItemText
                    primary="Completed"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Cancelled orders" ? true : false}
                  onClick={() => navigate("/cancelledorders")}
                >
                  <ListItemText
                    primary="Cancelled"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Inprocess orders" ? true : false}
                  onClick={() => navigate("/inprocessorders")}
                >
                  <ListItemText
                    primary="In Process"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "Product inventory" ? true : false}
                  onClick={() => navigate("/productinventory")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Inventory />
                  </ListItemIcon>
                  <ListItemText
                    primary="Product Inventory"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "Coupons list" ? true : false}
                  onClick={() => navigate("/coupons")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <LocalOffer />
                  </ListItemIcon>
                  <ListItemText
                    primary="Coupons"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Add new coupons" ? true : false}
                  onClick={() => navigate("/addnewcoupons")}
                >
                  <ListItemText
                    primary="Add new coupon"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          ) : null}

          {userDetail[0]?.role === "admin" ? (
            <>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "Users list" ? true : false}
                  onClick={() => navigate("/userlist")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <VerifiedUser />
                  </ListItemIcon>
                  <ListItemText
                    primary="Users List"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Vendor list" ? true : false}
                  onClick={() => navigate("/vendorlist")}
                >
                  <ListItemText
                    primary="Vendors"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "QA list" ? true : false}
                  onClick={() => navigate("/qalist")}
                >
                  <ListItemText primary="QA" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Customers list" ? true : false}
                  onClick={() => navigate("/customerlist")}
                >
                  <ListItemText
                    primary="Customers"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          ) : null}
        </List>
        <Divider />

        {userDetail[0]?.role === "admin" ? (
          <>
            <List>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "Category list" ? true : false}
                  onClick={() => navigate("/categorylist")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Category />
                  </ListItemIcon>
                  <ListItemText
                    primary="Category List"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Add new category" ? true : false}
                  onClick={() => navigate("/addnewcategory")}
                >
                  <ListItemText
                    primary="Add New"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "Attribute list" ? true : false}
                  onClick={() => navigate("/attributelist")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <EditAttributes />
                  </ListItemIcon>
                  <ListItemText
                    primary="Attribute List"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Add new attribute" ? true : false}
                  onClick={() => navigate("/addnewattribute")}
                >
                  <ListItemText
                    primary="Add New"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={menuBarName === "Fit list" ? true : false}
                onClick={() => navigate("/fitlist")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Accessibility />
                </ListItemIcon>
                <ListItemText
                  primary="Fit List"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 8.5,
                }}
                selected={menuBarName === "Add new fit" ? true : false}
                onClick={() => navigate("/addnewfit")}
              >
                <ListItemText
                  primary="Add New"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>

              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={menuBarName === "Fabric list" ? true : false}
                onClick={() => navigate("/fabriclist")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <MergeType />
                </ListItemIcon>
                <ListItemText
                  primary="Fabric List"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>

              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 8.5,
                }}
                selected={menuBarName === "Add new fabric" ? true : false}
                onClick={() => navigate("/addnewfabric")}
              >
                <ListItemText
                  primary="Add New"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </>
        ) : null}
        <Divider />

        {userDetail[0]?.role === "admin" ? (
          <>
            <List>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "Website banners" ? true : false}
                  onClick={() => navigate("/websitebanners")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Collections />
                  </ListItemIcon>
                  <ListItemText
                    primary="Website Banners"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "Blogs" ? true : false}
                  onClick={() => navigate("/blogs")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PostAdd />
                  </ListItemIcon>
                  <ListItemText
                    primary="Blogs"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Add new blog" ? true : false}
                  onClick={() => navigate("/addnewblog")}
                >
                  <ListItemText
                    primary="Add New Blog"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "blog category list" ? true : false}
                  onClick={() => navigate("/blogcategorylist")}
                >
                  <ListItemText
                    primary="Blog Category List"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={
                    menuBarName === "Add new blog category" ? true : false
                  }
                  onClick={() => navigate("/addnewblogcategory")}
                >
                  <ListItemText
                    primary="Add New Category"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "Services" ? true : false}
                  onClick={() => navigate("/services")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Handshake />
                  </ListItemIcon>
                  <ListItemText
                    primary="Services"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Add new service" ? true : false}
                  onClick={() => navigate("/addnewservice")}
                >
                  <ListItemText
                    primary="Add New service"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 8.5,
                  }}
                  selected={menuBarName === "Service requests" ? true : false}
                  onClick={() => navigate("/servicerequest")}
                >
                  <ListItemText
                    primary="Service Requests"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "About us" ? true : false}
                  onClick={() => navigate("/aboutus")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Person3 />
                  </ListItemIcon>
                  <ListItemText
                    primary="About Us"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={menuBarName === "Policies" ? true : false}
                  onClick={() => navigate("/policies")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Policy />
                  </ListItemIcon>
                  <ListItemText
                    primary="Website Policies"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </>
        ) : null}
      </Drawer>
    </>
  );
}
