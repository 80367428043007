import * as React from "react";

import { Box, TextField, Button } from "@mui/material";

import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";

export default function UpdateFit({ item, cancel }) {
  const [fitName, setFitName] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    await updateDoc(doc(db, "dashboard_fit", item.id), {
      name: fitName,
      editedDate: new Date().toString(),
    });

    setLoading(false);
  };

  React.useEffect(() => {
    setFitName(item.name);
  }, [item, cancel]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          label="Fit name"
          variant="outlined"
          size="medium"
          onChange={(e) => setFitName(e.target.value)}
          value={fitName}
        />

        <Button
          variant="contained"
          size="large"
          onClick={handleSubmit}
          disabled={loading === false ? false : true}
        >
          {loading === false ? "Submit" : "Please wait..."}
        </Button>
      </Box>
    </Box>
  );
}
