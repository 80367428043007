import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright(props) {
  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="">
          Your Website
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      {/* <Typography>Admin dashboard: Username- vicky@gmail.com, password- 123456</Typography>
      <Typography>Vendor dashboard: Username- vendor@gmail.com, password- 1234</Typography>
      <Typography>Qa dashboard: Username- qa@gmail.com, password- 123456</Typography> */}

    </>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function LoginScreen() {
  let userdetail = JSON.parse(localStorage.getItem("userdetail"));

  // console.log({userdetail})

  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    getData(data.get("email"), data.get("password"));
  };

  const getData = async (email, password) => {
    setLoading(true);

    const q = query(
      collection(db, "dashboard_users"),
      where("email", "==", email),
      where("password", "==", password),
      where("status", "==", true)
    );

    await getDocs(q).then((querySnapshot) => {
      setLoading(false);
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      localStorage.setItem("userdetail", JSON.stringify(newData));

      if (newData.length === 0) {
        alert("Not registered or not approved");
      } else {
        navigate("/dashboard");
      }
    });
  };
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {
    if (userdetail !== undefined && userdetail !== null && userdetail !== "") {
      setUserName(userdetail[0]?.username);
      setPassword(userdetail[0]?.password);
    }
  }, []);

  const handleChangePassword = async () => {
    setLoading(true);
    const q = query(
      collection(db, "dashboard_users"),
      where("username", "==", userName),
      where("email", "==", email),
      where("phone", "==", parseInt(phone))
    );

    await getDocs(q).then(async (querySnapshot) => {
      setLoading(false);
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (newData.length === 0) {
        setLoading(false);
        setOpenError(true);
      } else {
        await updateDoc(
          doc(db, "dashboard_users", newData[0].id),

          {
            password: password,
          }
        );
        setLoading(false);
        setOpen(false);
      }
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Snackbar
              open={openError}
              autoHideDuration={6000}
              onClose={handleClose2}
            >
              <Alert
                onClose={handleClose2}
                severity="error"
                sx={{ width: "100%" }}
              >
                Username or Email or Phone didn't match!
              </Alert>
            </Snackbar>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Change your password"}
              </DialogTitle>
              <DialogContent>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Username"
                  autoFocus
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Phone"
                  autoFocus
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="New Password"
                  autoFocus
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={handleChangePassword}
                  disabled={loading === true ? true : false}
                  variant="contained"
                >
                  {loading === true ? "Please wait.." : " Change"}
                </Button>
              </DialogActions>
            </Dialog>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />

              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword === true ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <IconButton
                  sx={{ marginLeft: "-3rem" }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword === true ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading ? true : false}
              >
                {loading ? "Please wait..." : "Sign In"}
              </Button>

              <Grid container>
                <Grid item xs>
                  <Button onClick={() => setOpen(true)}>Forgot password</Button>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
