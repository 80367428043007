import * as React from "react";

import { Box, Avatar, TextField, Button } from "@mui/material";

import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import { CameraAltSharp } from "@mui/icons-material";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function AddNewBlogCategory() {
  const [imageUrl, setImageUrl] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [categoryName, setCategoryName] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const handleImage = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (imageUrl) => {
    await addDoc(collection(db, "blogs_category"), {
      name: categoryName,
      image: imageUrl,
      status: true,
      createdDate: new Date().toString(),
    });

    window.location.reload();

    setLoading(false);
  };

  const fileInput = React.useRef();

  const handleUploadImage = async () => {
    if (categoryName !== "" && imageUrl !== "") {
      setLoading(true);
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_blog_category_images/" + file.name
      );

      await uploadBytes(mountainImagesRef, file).then(async (snapshot) => {
        await getDownloadURL(
          ref(storage, "dashboard_blog_category_images/" + file.name)
        ).then((url) => handleSubmit(url));
      });
    } else {
      alert("Error");
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Add new blog category" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            size="medium"
            onChange={(e) => setCategoryName(e.target.value)}
          />

          <input
            ref={fileInput}
            type="file"
            style={{ display: "none" }}
            onChange={handleImage}
          />

          <Button
            variant="contained"
            endIcon={<CameraAltSharp />}
            onClick={() => fileInput.current.click()}
            size="large"
            disabled={loading === false ? false : true}
          >
            Image
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleUploadImage}
            disabled={loading === false ? false : true}
          >
            {loading === false ? "Submit" : "Please wait..."}
          </Button>

          <Avatar
            alt=""
            src={imageUrl}
            variant="rounded"
            sx={{
              width: 56,
              height: 76,
              display: "table",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
