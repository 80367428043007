import * as React from "react";

import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

export default function AddNewFabric() {
  const [fabricName, setFabricName] = React.useState("");
  const [parentCategoryData, setParentCategoryData] = React.useState([]);
  const [parentCategory, setParentCategory] = React.useState("");
  const [parentCategoryId, setParentCategoryId] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (imageUrl) => {
    await addDoc(collection(db, "dashboard_fabric"), {
      name: fabricName,
      parent: parentCategory,

      status: true,

      createdDate: new Date().toString(),
    });

    window.location.reload();

    setLoading(false);
  };

  React.useEffect(() => {
    getParentCategory();
  }, []);

  const getParentCategory = async () => {
    await getDocs(collection(db, "dashboard_categories")).then(
      (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setParentCategoryData(newData);
      }
    );
  };
  const handleChange = (event) => {
    setParentCategory(event.target.value);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Add new fabric" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            size="medium"
            onChange={(e) => setFabricName(e.target.value)}
          />

          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Select category
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={parentCategory}
              onChange={handleChange}
              autoWidth
              label="Select parent category(If any)"
              size="medium"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {parentCategoryData?.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.name}
                  onClick={() => setParentCategoryId(item.id)}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            size="large"
            onClick={handleSubmit}
            disabled={loading === false ? false : true}
          >
            {loading === false ? "Submit" : "Please wait..."}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
