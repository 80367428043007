import * as React from "react";

import { Box, Avatar, TextField, Button, Typography } from "@mui/material";
// import moment from "moment/moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import { getDocs, addDoc, collection, where, query } from "firebase/firestore";
import { db } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import OutlinedInput from "@mui/material/OutlinedInput";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddNewCoupons() {
  let userdetail = JSON.parse(localStorage.getItem("userdetail"));

  const [loading, setLoading] = React.useState(false);
  const [couponCode, setCouponCode] = React.useState("");
  const [couponTypevalue, setCouponTypeValue] = React.useState("");
  const [couponUsedTimes, setCouponUsedTimes] = React.useState("");
  const [minCartAmnt, setMinCartAmnt] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [validDate, setValidDate] = React.useState("");

  const [categories, setCategories] = React.useState([]);
  const [products, setProduct] = React.useState([]);

  const [categoryId, setCategoryid] = React.useState([]);
  const [productId, setProductid] = React.useState([]);
  const [applyFor, setApplyFor] = React.useState("");
  const [couponType, setCouponType] = React.useState("");

  React.useEffect(() => {
    getCategories();
    getProducts();
  }, []);

  const getCategories = async () => {
    setLoading(true);
    await getDocs(collection(db, "dashboard_categories")).then(
      (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setCategories(newData);
        setLoading(false);
      }
    );
  };

  const getProducts = async () => {
    if (userdetail[0].role === "admin") {
      const q = query(collection(db, "dashboard_product"));
      await getDocs(q).then((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setProduct(newData);
        setLoading(false);
      });
    } else {
      const q = query(
        collection(db, "dashboard_product"),
        where("vendorId", "==", userdetail[0].id)
      );
      await getDocs(q).then((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setProduct(newData);
        setLoading(false);
      });
    }
  };

  const handleChangeCategory = (event) => {
    const {
      target: { value },
    } = event;
    setCategoryid(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeproduct = (event) => {
    const {
      target: { value },
    } = event;
    setProductid(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = async () => {
    if (
      couponCode !== "" &&
      categoryId.length > 0 &&
      applyFor !== "" &&
      couponType !== "" &&
      couponTypevalue !== "" &&
      startDate !== "" &&
      validDate !== ""
    ) {
      setLoading(true);
      await addDoc(collection(db, "dashboard_coupons"), {
        code: couponCode,
        appliedCat: categoryId,
        appliedproduct: productId,
        validTill: validDate.$d,
        startDate: startDate.$d,
        coupounType: couponType,
        applyFor: applyFor,
        couponTypevalue: couponTypevalue,
        couponUsedTimes: couponUsedTimes,
        minCartAmnt: minCartAmnt,
        vendorId: userdetail[0].id,
        role: userdetail[0].role,
        status: false,
        createdDate: new Date().toString(),
      });

      window.location.reload();

      setLoading(false);
    } else {
      alert("Fill all required field");
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Add new coupons" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Coupon code*"
            variant="outlined"
            size="medium"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">
              Applied on category*
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={categoryId}
              input={<OutlinedInput label="Applied on category*" />}
              MenuProps={MenuProps}
              onChange={handleChangeCategory}
            >
              {categories.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">
              Applied on product
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={productId}
              input={<OutlinedInput label="Applied on product" />}
              MenuProps={MenuProps}
              onChange={handleChangeproduct}
            >
              {products.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.productName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">Apply for*</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              input={<OutlinedInput label="Apply for*" />}
              MenuProps={MenuProps}
              value={applyFor}
              onChange={(e) => setApplyFor(e.target.value)}
            >
              <MenuItem value="productwise">Product wise</MenuItem>
              <MenuItem value="cartwise">Total cart item</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">Coupon type*</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              input={<OutlinedInput label="Coupon type*" />}
              MenuProps={MenuProps}
              value={couponType}
              onChange={(e) => setCouponType(e.target.value)}
            >
              <MenuItem value="percentage">Percentage(%)</MenuItem>
              <MenuItem value="amnt">Amount(₹)</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="outlined-basic"
            label="Amnt/%*"
            variant="outlined"
            size="medium"
            value={couponTypevalue}
            type="number"
            onChange={(e) => setCouponTypeValue(e.target.value)}
          />

          <TextField
            id="outlined-basic"
            label="Times used by user"
            variant="outlined"
            size="medium"
            value={couponUsedTimes}
            type="number"
            onChange={(e) => setCouponUsedTimes(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Minimum cart amnt"
            variant="outlined"
            size="medium"
            value={minCartAmnt}
            type="number"
            onChange={(e) => setMinCartAmnt(e.target.value)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start date*"
              value={startDate}
              onChange={setStartDate}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Valid till date*"
              value={validDate}
              onChange={setValidDate}
            />
          </LocalizationProvider>

          <Button
            variant="contained"
            size="large"
            disabled={loading === false ? false : true}
            onClick={handleSubmit}
          >
            {loading === false ? "Submit" : "Please wait..."}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
