import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { Paper } from "@mui/material";
import { PictureAsPdf, Visibility } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate, useLocation } from "react-router-dom";



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function VendorVerification() {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = React.useState(false);

  const [value, setValue] = React.useState(0);
  const [frontImage, setFrontImage] = React.useState("");
  const [backImage, setBackImage] = React.useState("");
  const [panImage, setPanImage] = React.useState("");
  const [businessType, setBusinessType] = React.useState("");
  const [chequeImage, setChequeImage] = React.useState("");
  const [gstImage, setGstImage] = React.useState("");
  const [aadharNumber, setAadharNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");

  const [bankName, setBankName] = React.useState("");

  const [accountNumber, setAccountNumber] = React.useState("");
  const [holderName, setHolderName] = React.useState("");
  const [ifsc, setIfsc] = React.useState("");
  const [businessName, setBusinessName] = React.useState("");
  const [storePincode, setStorePincode] = React.useState("");
  const [storestate, setStoreState] = React.useState("");

  const handleChangeBusinessType = (event) => {
    setBusinessType(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setAadharNumber(location.state.data.kycData[0]?.aadharNumber);
    setFrontImage(location.state.data.kycData[0]?.aadharFrontImage);
    setBackImage(location.state.data.kycData[0]?.aadharBackImage);
    setPanNumber(location.state.data.kycData[0]?.panNumber);
    setPanImage(location.state.data.kycData[0]?.panImage);
    setBankName(location.state.data.accountInfo[0]?.bankName);
    setAccountNumber(location.state.data.accountInfo[0]?.accountNumber);
    setHolderName(location.state.data.accountInfo[0]?.holderName);
    setIfsc(location.state.data.accountInfo[0]?.ifsc);
    setChequeImage(location.state.data.accountInfo[0]?.chequeImage);
    setBusinessName(location.state.data.businessInfo[0]?.businessName);
    setBusinessType(location.state.data.businessInfo[0]?.businessType);
    setStoreState(location.state.data.businessInfo[0]?.storeState);
    setStorePincode(location.state.data.businessInfo[0]?.storePincode);
    setStorePincode(location.state.data.businessInfo[0]?.storePincode);
    setGstImage(location.state.data.businessInfo[0]?.gstDoc);
  }, [location]);

  const handleVerify = async () => {
    setLoading(true);
    await updateDoc(doc(db, "dashboard_users", location.state.data.id), {
      kycDone: true,
    });
    setLoading(false);
    navigate("/vendorlist");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Vendor verification" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="KYC Data" {...a11yProps(0)} />
            <Tab label="Account Detail" {...a11yProps(1)} />
            <Tab label="Business Info" {...a11yProps(2)} />
          </Tabs>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                marginRight: "2vw",
              }}
              onClick={handleVerify}
              disabled={loading === true ? true : false}
            >
              {loading === false ? "Verify" : "Please wait..."}
            </Button>

            <Button
              size="large"
              variant="outlined"
              onClick={() => navigate("/vendorlist")}
            >
              Cancel
            </Button>
          </Box>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <TextField
            fullWidth
            label="Adhar number"
            id="adhanumber"
            onChange={(e) => setAadharNumber(e.target.value)}
            value={aadharNumber}
            required
            sx={{ width: 500 }}
          />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",

              borderBottom: 2,
              paddingBottom: 3,
              borderBottomColor: "darkgray",

              "& > :not(style)": {
                m: 1,
                width: "30%",
                height: 128,
              },
            }}
          >
            <Paper elevation={0}>
              <Typography>Front image</Typography>

              <img
                alt=""
                src={frontImage}
                style={{
                  width: "106px",
                  height: "106px",
                }}
              />
              <IconButton
                onClick={() => window.open(frontImage, "_blank").focus()}
              >
                <Visibility color="primary" />
              </IconButton>
            </Paper>

            <Paper elevation={0}>
              <Typography>Back image</Typography>

              <img
                alt=""
                src={backImage}
                style={{
                  width: "106px",
                  height: "106px",
                }}
              />
              <IconButton
                onClick={() => window.open(backImage, "_blank").focus()}
              >
                <Visibility color="primary" />
              </IconButton>
            </Paper>
          </Box>

          <TextField
            fullWidth
            label="PAN number"
            id="pannumber"
            onChange={(e) => setPanNumber(e.target.value)}
            value={panNumber}
            required
            sx={{ width: 500, marginTop: 3 }}
          />

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",

              "& > :not(style)": {
                m: 1,
                width: "30%",
                height: 128,
              },
            }}
          >
            <Paper elevation={0}>
              <Typography>Pancard image</Typography>

              <img
                alt=""
                src={panImage}
                style={{
                  width: "106px",
                  height: "106px",
                }}
              />
              <IconButton
                onClick={() => window.open(panImage, "_blank").focus()}
              >
                <Visibility color="primary" />
              </IconButton>
            </Paper>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <TextField
            fullWidth
            label="Bank name"
            id="bankname"
            onChange={(e) => setBankName(e.target.value)}
            value={bankName}
            required
            sx={{ width: 500, marginBottom: 2, marginRight: 2 }}
          />
          <TextField
            fullWidth
            label="Account number"
            id="accountnumber"
            onChange={(e) => setAccountNumber(e.target.value)}
            value={accountNumber}
            required
            sx={{ width: 500, marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Account holder name"
            id="accountholdername"
            onChange={(e) => setHolderName(e.target.value)}
            value={holderName}
            required
            sx={{ width: 500, marginBottom: 2, marginRight: 2 }}
          />

          <TextField
            fullWidth
            label="Account IFSC"
            id="accountifsc"
            onChange={(e) => setIfsc(e.target.value)}
            value={ifsc}
            required
            sx={{ width: 500, marginBottom: 2 }}
          />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",

              "& > :not(style)": {
                m: 1,
                width: "30%",
                height: 128,
              },
            }}
          >
            <Paper elevation={0}>
              <Typography>Cancel cheque</Typography>

              <img
                alt=""
                src={chequeImage}
                style={{
                  width: "106px",
                  height: "106px",
                }}
              />
              <IconButton
                onClick={() => window.open(chequeImage, "_blank").focus()}
              >
                <Visibility color="primary" />
              </IconButton>
            </Paper>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <TextField
            fullWidth
            label="Business name"
            id="businessname"
            onChange={(e) => setBusinessName(e.target.value)}
            value={businessName}
            required
            sx={{ width: 500, marginBottom: 2, marginRight: 2 }}
          />
          <FormControl sx={{ marginBottom: 2, minWidth: 500 }}>
            <InputLabel id="demo-select-small-label">Business type</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={businessType}
              label="Business type"
              onChange={handleChangeBusinessType}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Clothing">Clothing</MenuItem>
              <MenuItem value="Electronics">Electronics</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Store state"
            id="storestate"
            onChange={(e) => setStoreState(e.target.value)}
            value={storestate}
            required
            sx={{ width: 500, marginBottom: 2, marginRight: 2 }}
          />
          <TextField
            fullWidth
            label="Store pin code"
            id="storepincode"
            onChange={(e) => setStorePincode(e.target.value)}
            value={storePincode}
            required
            sx={{ width: 500, marginBottom: 2 }}
          />

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",

              "& > :not(style)": {
                m: 1,
                width: "30%",
                height: 128,
              },
            }}
          >
            <Paper elevation={0}>
              <Typography>GST doc (Pdf only)*</Typography>

              <PictureAsPdf
                color="success"
                sx={{ width: "106px", height: "106px" }}
              />
              <IconButton
                onClick={() => window.open(gstImage, "_blank").focus()}
              >
                <Visibility color="primary" />
              </IconButton>
              
            </Paper>
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
