import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import TextField from "@mui/material/TextField";
import { Paper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { Add, PictureAsPdf } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  let userdetail = JSON.parse(localStorage.getItem("userdetail"));
  const navigate = useNavigate();

  const [loadingFront, setLoadingFront] = React.useState(false);
  const [loadingBack, setLoadingBack] = React.useState(false);
  const [loadingPan, setLoadingPan] = React.useState(false);
  const [loadingCheque, setLoadingCheque] = React.useState(false);
  const [loadingGstDoc, setLoadingGstDoc] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [errorShow, setErrorShow] = React.useState(false);

  const [value, setValue] = React.useState(0);
  const [frontImage, setFrontImage] = React.useState("");
  const [backImage, setBackImage] = React.useState("");
  const [panImage, setPanImage] = React.useState("");
  const [businessType, setBusinessType] = React.useState("");
  const [chequeImage, setChequeImage] = React.useState("");
  const [gstImage, setGstImage] = React.useState("");
  const [docName, setDocName] = React.useState("");
  const [aadharNumber, setAadharNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");

  const [bankName, setBankName] = React.useState("");

  const [accountNumber, setAccountNumber] = React.useState("");
  const [holderName, setHolderName] = React.useState("");
  const [ifsc, setIfsc] = React.useState("");
  const [businessName, setBusinessName] = React.useState("");
  const [storePincode, setStorePincode] = React.useState("");
  const [storestate, setStoreState] = React.useState("");
  const [kycApplied, setKycApplied] = React.useState(false);

  const handleChangeBusinessType = (event) => {
    setBusinessType(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (
      userdetail[0]?.kycApplied === false ||
      userdetail[0]?.kycDone === false
    ) {
      setOpen(true);
    }

    if (userdetail[0]?.kycApplied === true) {
      setKycApplied(true);
      setAadharNumber(userdetail[0]?.kycData[0]?.aadharNumber);
      setFrontImage(userdetail[0]?.kycData[0]?.aadharFrontImage);
      setBackImage(userdetail[0]?.kycData[0]?.aadharBackImage);
      setPanNumber(userdetail[0]?.kycData[0]?.panNumber);
      setPanImage(userdetail[0]?.kycData[0]?.panImage);
      setBankName(userdetail[0]?.accountInfo[0]?.bankName);
      setAccountNumber(userdetail[0]?.accountInfo[0]?.accountNumber);
      setHolderName(userdetail[0]?.accountInfo[0]?.holderName);
      setIfsc(userdetail[0]?.accountInfo[0]?.ifsc);
      setChequeImage(userdetail[0]?.accountInfo[0]?.chequeImage);
      setBusinessName(userdetail[0]?.businessInfo[0]?.businessName);
      setBusinessType(userdetail[0]?.businessInfo[0]?.businessType);
      setStoreState(userdetail[0]?.businessInfo[0]?.storeState);
      setStorePincode(userdetail[0]?.businessInfo[0]?.storePincode);
      setStorePincode(userdetail[0]?.businessInfo[0]?.storePincode);
      setGstImage(userdetail[0]?.businessInfo[0]?.gstDoc);
    }
  }, [userdetail]);

  const handleChangeFront = async (e) => {
    setLoadingFront(true);
    const storage = getStorage();

    const mountainImagesRef = ref(
      storage,
      "dashboard_vendorkyc_images_" +
        userdetail[0]?.id +
        "/" +
        e.target.files[0].name
    );

    await uploadBytes(mountainImagesRef, e.target.files[0]).then(
      async (snapshot) => {
        await getDownloadURL(
          ref(
            storage,
            "dashboard_vendorkyc_images_" +
              userdetail[0]?.id +
              "/" +
              e.target.files[0].name
          )
        ).then((url) => {
          setFrontImage(url);
        });
      }
    );
    setLoadingFront(false);
  };

  const handleChangeBack = async (e) => {
    setLoadingBack(true);
    const storage = getStorage();

    const mountainImagesRef = ref(
      storage,
      "dashboard_vendorkyc_images_" +
        userdetail[0]?.id +
        "/" +
        e.target.files[0].name
    );

    await uploadBytes(mountainImagesRef, e.target.files[0]).then(
      async (snapshot) => {
        await getDownloadURL(
          ref(
            storage,
            "dashboard_vendorkyc_images_" +
              userdetail[0]?.id +
              "/" +
              e.target.files[0].name
          )
        ).then((url) => {
          setBackImage(url);
        });
      }
    );
    setLoadingBack(false);
  };

  const handleChangePancard = async (e) => {
    setLoadingPan(true);
    const storage = getStorage();

    const mountainImagesRef = ref(
      storage,
      "dashboard_vendorkyc_images_" +
        userdetail[0]?.id +
        "/" +
        e.target.files[0].name
    );

    await uploadBytes(mountainImagesRef, e.target.files[0]).then(
      async (snapshot) => {
        await getDownloadURL(
          ref(
            storage,
            "dashboard_vendorkyc_images_" +
              userdetail[0]?.id +
              "/" +
              e.target.files[0].name
          )
        ).then((url) => {
          setPanImage(url);
        });
      }
    );
    setLoadingPan(false);
  };

  const handleChangeCheque = async (e) => {
    setLoadingCheque(true);
    const storage = getStorage();

    const mountainImagesRef = ref(
      storage,
      "dashboard_vendorkyc_images_" +
        userdetail[0]?.id +
        "/" +
        e.target.files[0].name
    );

    await uploadBytes(mountainImagesRef, e.target.files[0]).then(
      async (snapshot) => {
        await getDownloadURL(
          ref(
            storage,
            "dashboard_vendorkyc_images_" +
              userdetail[0]?.id +
              "/" +
              e.target.files[0].name
          )
        ).then((url) => {
          setChequeImage(url);
        });
      }
    );
    setLoadingCheque(false);
  };

  const handleChangeGstDoc = async (e) => {
    setLoadingGstDoc(true);
    const storage = getStorage();

    const mountainImagesRef = ref(
      storage,
      "dashboard_vendorkyc_images_" +
        userdetail[0]?.id +
        "/" +
        e.target.files[0].name
    );

    await uploadBytes(mountainImagesRef, e.target.files[0]).then(
      async (snapshot) => {
        await getDownloadURL(
          ref(
            storage,
            "dashboard_vendorkyc_images_" +
              userdetail[0]?.id +
              "/" +
              e.target.files[0].name
          )
        ).then((url) => {
          setGstImage(url);
          setDocName(e.target.files[0].name);
        });
      }
    );
    setLoadingGstDoc(false);
  };

  const handleSubmit = async () => {
    if (
      aadharNumber !== "" &&
      panNumber !== "" &&
      frontImage !== "" &&
      backImage !== "" &&
      panImage !== "" &&
      bankName !== "" &&
      accountNumber !== "" &&
      holderName !== "" &&
      ifsc !== "" &&
      chequeImage !== "" &&
      businessName !== "" &&
      businessType !== "" &&
      storePincode !== "" &&
      gstImage !== "" &&
      storestate !== ""
    ) {
      setLoading(true);
      await updateDoc(doc(db, "dashboard_users", userdetail[0]?.id), {
        kycApplied: true,
        businessInfo: [
          {
            businessName: businessName,
            businessType: businessType,
            gstDoc: gstImage,
            storePincode: storePincode,
            storeState: storestate,
          },
        ],
        kycData: [
          {
            aadharNumber: aadharNumber,
            panNumber: panNumber,
            aadharFrontImage: frontImage,
            aadharBackImage: backImage,
            panImage: panImage,
          },
        ],
        accountInfo: [
          {
            bankName: bankName,
            accountNumber: accountNumber,
            holderName: holderName,
            ifsc: ifsc,
            chequeImage: chequeImage,
          },
        ],
      });
      setLoading(false);
      setKycApplied(true);
    } else {
      setErrorShow(true);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Dashboard" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Snackbar
          open={errorShow}
          autoHideDuration={6000}
          onClose={() => setErrorShow(false)}
        >
          <Alert
            onClose={() => setErrorShow(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Please fill all required fields!
          </Alert>
        </Snackbar>

        {userdetail[0]?.role === "vendor" ? (
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Please complete KYC first"}
            </DialogTitle>
            <DialogContent>
              {kycApplied === true && userdetail[0]?.kycApplied === true ? (
                <Typography>Under verification</Typography>
              ) : (
                <Box>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="KYC Data" {...a11yProps(0)} />
                      <Tab label="Account Detail" {...a11yProps(1)} />
                      <Tab label="Business Info" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <TextField
                      fullWidth
                      label="Adhar number"
                      id="adhanumber"
                      onChange={(e) => setAadharNumber(e.target.value)}
                      value={aadharNumber}
                      required
                      sx={{ width: 500 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",

                        borderBottom: 2,
                        paddingBottom: 3,
                        borderBottomColor: "darkgray",

                        "& > :not(style)": {
                          m: 1,
                          width: "30%",
                          height: 128,
                        },
                      }}
                    >
                      <Paper elevation={0}>
                        <input
                          accept="image/*"
                          name="front"
                          id="front"
                          // multiple
                          type="file"
                          onChange={(e) => handleChangeFront(e)}
                          style={{
                            display: "none",
                            width: "106px",
                            height: "106px",
                          }}
                        />
                        <Typography>Front image</Typography>

                        {loadingFront === true ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <label htmlFor="front">
                            {frontImage !== "" ? (
                              <img
                                alt=""
                                src={frontImage}
                                style={{
                                  width: "106px",
                                  height: "106px",
                                }}
                              />
                            ) : (
                              <Button
                                component="span"
                                sx={{
                                  backgroundColor: "#CEF4FF",
                                  width: "125px",
                                  height: "125px",
                                  border: "dotted",
                                  borderWidth: "2px",
                                  borderColor: "blue",
                                }}
                              >
                                <Add color="primary" />
                              </Button>
                            )}
                          </label>
                        )}
                      </Paper>

                      <Paper elevation={0}>
                        <input
                          accept="image/*"
                          name="back"
                          id="back"
                          // multiple
                          type="file"
                          onChange={(e) => handleChangeBack(e)}
                          style={{
                            display: "none",
                            width: "106px",
                            height: "106px",
                          }}
                        />
                        <Typography>Back image</Typography>

                        {loadingBack === true ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <label htmlFor="back">
                            {backImage !== "" ? (
                              <img
                                alt=""
                                src={backImage}
                                style={{
                                  width: "106px",
                                  height: "106px",
                                }}
                              />
                            ) : (
                              <Button
                                component="span"
                                sx={{
                                  backgroundColor: "#CEF4FF",
                                  width: "125px",
                                  height: "125px",
                                  border: "dotted",
                                  borderWidth: "2px",
                                  borderColor: "blue",
                                }}
                              >
                                <Add color="primary" />
                              </Button>
                            )}
                          </label>
                        )}
                      </Paper>
                    </Box>

                    <TextField
                      fullWidth
                      label="PAN number"
                      id="pannumber"
                      onChange={(e) => setPanNumber(e.target.value)}
                      value={panNumber}
                      required
                      sx={{ width: 500, marginTop: 3 }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",

                        "& > :not(style)": {
                          m: 1,
                          width: "30%",
                          height: 128,
                        },
                      }}
                    >
                      <Paper elevation={0}>
                        <input
                          accept="image/*"
                          name="pancard"
                          id="pancard"
                          // multiple
                          type="file"
                          onChange={(e) => handleChangePancard(e)}
                          style={{
                            display: "none",
                            width: "106px",
                            height: "106px",
                          }}
                        />
                        <Typography>Pancard image</Typography>

                        {loadingPan === true ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <label htmlFor="pancard">
                            {panImage !== "" ? (
                              <img
                                alt=""
                                src={panImage}
                                style={{
                                  width: "106px",
                                  height: "106px",
                                }}
                              />
                            ) : (
                              <Button
                                component="span"
                                sx={{
                                  backgroundColor: "#CEF4FF",
                                  width: "125px",
                                  height: "125px",
                                  border: "dotted",
                                  borderWidth: "2px",
                                  borderColor: "blue",
                                }}
                              >
                                <Add color="primary" />
                              </Button>
                            )}
                          </label>
                        )}
                      </Paper>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <TextField
                      fullWidth
                      label="Bank name"
                      id="bankname"
                      onChange={(e) => setBankName(e.target.value)}
                      value={bankName}
                      required
                      sx={{ width: 500, marginBottom: 2 }}
                    />
                    <TextField
                      fullWidth
                      label="Account number"
                      id="accountnumber"
                      onChange={(e) => setAccountNumber(e.target.value)}
                      value={accountNumber}
                      required
                      sx={{ width: 500, marginBottom: 2 }}
                    />
                    <TextField
                      fullWidth
                      label="Account holder name"
                      id="accountholdername"
                      onChange={(e) => setHolderName(e.target.value)}
                      value={holderName}
                      required
                      sx={{ width: 500, marginBottom: 2 }}
                    />

                    <TextField
                      fullWidth
                      label="Account IFSC"
                      id="accountifsc"
                      onChange={(e) => setIfsc(e.target.value)}
                      value={ifsc}
                      required
                      sx={{ width: 500, marginBottom: 2 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",

                        "& > :not(style)": {
                          m: 1,
                          width: "30%",
                          height: 128,
                        },
                      }}
                    >
                      <Paper elevation={0}>
                        <input
                          accept="image/*"
                          name="checque"
                          id="checque"
                          // multiple
                          type="file"
                          onChange={(e) => handleChangeCheque(e)}
                          style={{
                            display: "none",
                            width: "106px",
                            height: "106px",
                          }}
                        />
                        <Typography>Cancel cheque</Typography>

                        {loadingCheque === true ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <label htmlFor="checque">
                            {chequeImage !== "" ? (
                              <img
                                alt=""
                                src={chequeImage}
                                style={{
                                  width: "106px",
                                  height: "106px",
                                }}
                              />
                            ) : (
                              <Button
                                component="span"
                                sx={{
                                  backgroundColor: "#CEF4FF",
                                  width: "125px",
                                  height: "125px",
                                  border: "dotted",
                                  borderWidth: "2px",
                                  borderColor: "blue",
                                }}
                              >
                                <Add color="primary" />
                              </Button>
                            )}
                          </label>
                        )}
                      </Paper>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <TextField
                      fullWidth
                      label="Business name"
                      id="businessname"
                      onChange={(e) => setBusinessName(e.target.value)}
                      value={businessName}
                      required
                      sx={{ width: 500, marginBottom: 2 }}
                    />
                    <FormControl sx={{ marginBottom: 2, minWidth: 500 }}>
                      <InputLabel id="demo-select-small-label">
                        Business type
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={businessType}
                        label="Business type"
                        onChange={handleChangeBusinessType}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Clothing">Clothing</MenuItem>
                        <MenuItem value="Electronics">Electronics</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label="Store state"
                      id="storestate"
                      onChange={(e) => setStoreState(e.target.value)}
                      value={storestate}
                      required
                      sx={{ width: 500, marginBottom: 2 }}
                    />
                    <TextField
                      fullWidth
                      label="Store pin code"
                      id="storepincode"
                      onChange={(e) => setStorePincode(e.target.value)}
                      value={storePincode}
                      required
                      sx={{ width: 500, marginBottom: 2 }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",

                        "& > :not(style)": {
                          m: 1,
                          width: "30%",
                          height: 128,
                        },
                      }}
                    >
                      <Paper elevation={0}>
                        <input
                          name="gstdoc"
                          id="gstdoc"
                          // multiple
                          type="file"
                          onChange={(e) => handleChangeGstDoc(e)}
                          style={{
                            display: "none",
                            width: "106px",
                            height: "106px",
                          }}
                        />
                        <Typography>GST doc (Pdf only)*</Typography>

                        {loadingGstDoc === true ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <label htmlFor="gstdoc">
                            {gstImage !== "" ? (
                              <>
                                <PictureAsPdf
                                  color="success"
                                  sx={{ width: "106px", height: "106px" }}
                                />
                                <Typography>{docName}</Typography>
                              </>
                            ) : (
                              <Button
                                component="span"
                                sx={{
                                  backgroundColor: "#CEF4FF",
                                  width: "125px",
                                  height: "125px",
                                  border: "dotted",
                                  borderWidth: "2px",
                                  borderColor: "blue",
                                }}
                              >
                                <Add color="primary" />
                              </Button>
                            )}
                          </label>
                        )}
                      </Paper>
                    </Box>
                  </CustomTabPanel>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              {kycApplied === false ? (
                <>
                  <Button
                    onClick={handleLogout}
                    variant="outlined"
                    disabled={loading === true ? true : false}
                  >
                    Logout
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={loading === true ? true : false}
                  >
                    {loading === true ? "Please wait..." : "Submit KYC"}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={handleLogout}
                    variant="outlined"
                    disabled={loading === true ? true : false}
                  >
                    Logout
                  </Button>
                  <Button
                    onClick={() => setKycApplied(false)}
                    variant="contained"
                  >
                    Re-submit
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        ) : null}
      </Box>
    </Box>
  );
}
