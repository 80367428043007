import * as React from "react";

import Box from "@mui/material/Box";
import {
  Autocomplete,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";
import { Add, Delete, Remove } from "@mui/icons-material";
import { getDocs, collection, query, where, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddNewProduct() {
  const navigate = useNavigate();

  let userdetail = JSON.parse(localStorage.getItem("userdetail"));

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(true);
  const [open1, setOpen1] = React.useState(false);
  const [addNewProduct, setAddNewProduct] = React.useState(false);
  const [productName, setProductName] = React.useState("");
  const [productShortDesc, setproductShortDesc] = React.useState("");
  const [productStyleCode, setproductStyleCode] = React.useState("");
  const [productBrand, setproductBrand] = React.useState("");
  const [productBasePrice, setproductBasePrice] = React.useState("");
  const [productSalePrice, setproductSalePrice] = React.useState("");
  const [productHeight, setproductHeight] = React.useState("");
  const [productWeight, setproductWeight] = React.useState("");
  const [productWidth, setproductWidth] = React.useState("");
  const [productLength, setproductLength] = React.useState("");

  const [categoryData, setCategoryData] = React.useState([]);
  const [subCategoryData, setSubCategoryData] = React.useState([]);
  const [subCategoryType, setSubCategoryType] = React.useState([]);

  const [fitData, setFitData] = React.useState([]);
  const [fabricData, setFabricData] = React.useState([]);

  const [attributes, setAttributes] = React.useState([]);
  const [attributeValues, setAttributeValues] = React.useState([]);
  const [tags] = React.useState(["Tags"]);
  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);

  const [tagsValues, setTagsValues] = React.useState([]);
  const [indexThumb, setIndexThumb] = React.useState(0);
  const [category, setCategory] = React.useState({});
  const [randomNumber, setRandomNumber] = React.useState(new Date());
  const [loading2, setLoading2] = React.useState(false);
  const [productList, setProductList] = React.useState([]);

  const [variants, setVariants] = React.useState([
    {
      value: "",
      thumb: "",
      gallery: [{ order: 0, link: "" }],
      qty: 0,
      sku: "",
    },
  ]);

  const [longDesc, setLongDesc] = React.useState([
    {
      title: "",
      descthumb: "",
      desc: "",
    },
  ]);

  const [categoryType, setCategoryType] = React.useState("");
  const [fit, setFit] = React.useState("");
  const [fabric, setFabric] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  React.useEffect(() => {
    getCategories();

    getProducts();
  }, []);

  const getProducts = async () => {
    const q = query(
      collection(db, "dashboard_product"),
      where("vendorId", "==", userdetail[0]?.id)
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setProductList(newData);
    });
  };

  const getCategories = async () => {
    setLoading(true);
    const q = query(
      collection(db, "dashboard_categories"),
      where("parent", "==", "")
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setCategoryData(newData);
      setLoading(false);
    });
  };

  const getFit = async (e) => {
    const q = query(collection(db, "dashboard_fit"));
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setFitData(newData);
    });
  };

  const getFabric = async (e) => {
    const q = query(
      collection(db, "dashboard_fabric"),
      
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setFabricData(newData);
    });
  };

  const handleSelectMainCategory = (e) => {
    setOpen(false);
    setOpen1(true);
    getFit(e);
    getFabric(e);
    getSubCategories(e);
  };

  const handleSelectSubCategory = (e) => {
    setOpen1(false);
    setAddNewProduct(true);
    setCategory(e);
    setAttributes(e.attributes);
    getSubCategoryType(e.name);
  };

  const getSubCategories = async (parent) => {
    setLoading(true);
    const q = query(
      collection(db, "dashboard_categories"),
      where("parent", "==", parent)
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setSubCategoryData(newData);
      setLoading(false);
    });
  };

  const getSubCategoryType = async (parent) => {
    // setLoading(true);
    const q = query(
      collection(db, "dashboard_categories"),
      where("parent", "==", parent)
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setSubCategoryType(newData);
    });
  };

  const handleAddVariants = () => {
    setRandomNumber(randomNumber + 1);
    setVariants([
      ...variants,
      {
        value: "",
        thumb: "",
        gallery: [{ order: 0, link: "" }],
        qty: 0,
        sku: "",
      },
    ]);
  };

  const handleAddLongDesc = () => {
    setLongDesc([
      ...longDesc,
      {
        title: "",
        descthumb: "",
        desc: "",
      },
    ]);
  };

  const handleChangeThumnail = async (e) => {
    setLoading1(true);
    if (e.target.files[0]) {
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_product_images_" +
          userdetail[0]?.id +
          "/" +
          e.target.files[0]?.name
      );

      await uploadBytes(mountainImagesRef, e.target.files[0]).then(
        async (snapshot) => {
          await getDownloadURL(
            ref(
              storage,
              "dashboard_product_images_" +
                userdetail[0]?.id +
                "/" +
                e.target.files[0]?.name
            )
          ).then((url) => {
            const { name } = e.target;

            const list = [...variants];

            list[indexThumb][name] = url;
            setVariants(list);
            // setFile(e.target.files[0]);
          });
        }
      );
    }
    setLoading1(false);
  };

  const handleChangeDescThumnail = async (e) => {
    setLoading1(true);
    if (e.target.files[0]) {
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_product_images_" +
          userdetail[0]?.id +
          "/" +
          e.target.files[0]?.name
      );

      await uploadBytes(mountainImagesRef, e.target.files[0]).then(
        async (snapshot) => {
          await getDownloadURL(
            ref(
              storage,
              "dashboard_product_images_" +
                userdetail[0]?.id +
                "/" +
                e.target.files[0]?.name
            )
          ).then((url) => {
            const { name } = e.target;

            const list = [...longDesc];

            list[indexThumb][name] = url;
            setLongDesc(list);
          });
        }
      );
    }
    setLoading1(false);
  };

  const handleChangeGallery = async (e) => {
    setLoading2(true);
    if (e.target.files[0]) {
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_product_images_" +
          userdetail[0]?.id +
          "/" +
          e.target.files[0]?.name
      );

      await uploadBytes(mountainImagesRef, e.target.files[0]).then(
        async (snapshot) => {
          await getDownloadURL(
            ref(
              storage,
              "dashboard_product_images_" +
                userdetail[0]?.id +
                "/" +
                e.target.files[0]?.name
            )
          ).then((url) => {
            const list = [...variants];

            let data = {
              order:
                variants[indexThumb].gallery[
                  variants[indexThumb].gallery.length - 1
                ].order + 1,

              link: url,
            };

            list[indexThumb].gallery.push(data);

            setVariants(list);
          });
        }
      );
    }
    setLoading2(false);
  };

  const handleChangeValue = (e, idx) => {
    const { name, value } = e.target;

    const list = [...variants];

    list[idx][name] = value;
    list[idx]["sku"] =
      userdetail[0].id + JSON.stringify(productList.length) + value;

    setVariants(list);
  };

  const handleChangeTitle = (e, idx) => {
    const { name, value } = e.target;

    const list = [...longDesc];

    list[idx][name] = value;

    setLongDesc(list);
  };

  const handleChangeDesc = (e, idx) => {
    const { name, value } = e.target;

    const list = [...longDesc];

    list[idx][name] = value;

    setLongDesc(list);
  };

  const removeGalleryImage = (ii, i) => {
    const list = [...variants];

    list[ii].gallery.splice(i, 1);

    setVariants(list);
  };

  const removeDesc = (ii) => {
    const list = [...longDesc];

    list.splice(ii, 1);

    setLongDesc(list);
  };

  const removeVariant = (ii) => {
    const list = [...variants];

    list.splice(ii, 1);

    setVariants(list);
  };

  const handleSubmit = async () => {
    if (
      productName !== "" &&
      productShortDesc !== "" &&
      longDesc[0].title !== "" &&
      longDesc[0].desc !== "" &&
      longDesc[0].descthumb !== "" &&
      productStyleCode !== "" &&
      tagsValues.Tags.value.length > 0 &&
      productBrand !== "" &&
      productBasePrice !== "" &&
      productSalePrice !== "" &&
      productLength !== "" &&
      productWeight !== "" &&
      productWidth !== "" &&
      variants.map((el) => el.gallery.length > 1)
    ) {
      setLoading(true);
      await addDoc(collection(db, "dashboard_product"), {
        productName: productName,
        productShortDesc: productShortDesc,
        productLongDesc: longDesc,
        productStyleCode: productStyleCode,
        tags: tagsValues,
        productBrand: productBrand,
        attributes: attributeValues,
        productBasePrice: productBasePrice,
        productSalePrice: productSalePrice,
        productHeight: productHeight,
        productWeight: productWeight,
        productWidth: productWidth,
        productLength: productLength,
        variants: variants,
        category: category,
        status: false,
        qaChecked: false,
        drafted: false,
        isNew: true,
        createdDate: new Date().toString(),
        editedDate: new Date().toString(),
        vendorId: userdetail[0].id,
        categoryType: categoryType,
        productRating: [
          {
            customerId: "",
            gallery: [{ link: "" }],
            ratingCount: 3,
            ratingText: "",
          },
        ],
        fit: fit,
        fabric: fabric,
        vendor: [
          {
            name: userdetail[0].username,
            id: userdetail[0].id,
            address: "address",
            pincode: 302033,
          },
        ],
        isNewArrival: false,
        isBestSelling: false,
        isFeatured: false,
        isAds: false,
        isRejected: false,
        rejectedMsg: "",
        approvedBy: [],
        isQaEdit: false,
        qaEditDetail: [],
      });

      navigate("/productlist");
    } else {
      setOpen2(true);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Add new product" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ width: "100%" }}>
          <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
            <Alert
              onClose={handleClose2}
              severity="error"
              sx={{ width: "100%" }}
            >
              Please fill all required fields!
            </Alert>
          </Snackbar>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Product data" {...a11yProps(0)} />
              <Tab label="Variations" {...a11yProps(1)} />
            </Tabs>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginRight: "2vw",
                }}
                onClick={handleSubmit}
                disabled={loading === true ? true : false}
              >
                {loading === false ? "Submit" : "Please wait..."}
              </Button>
              <Button
                size="large"
                variant="outlined"
                onClick={() => navigate("/productlist")}
              >
                Cancel
              </Button>
            </Box>
          </Box>

          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Select main category"}
            </DialogTitle>
            <DialogContent>
              {loading === true ? (
                <Box sx={{ width: "100%" }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              ) : (
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  {categoryData.map((item, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      onClick={() => handleSelectMainCategory(item.name)}
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar src={item.image} alt="" />
                        </ListItemAvatar>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open1}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Select sub category"}
            </DialogTitle>
            <DialogContent>
              {loading === true ? (
                <Box sx={{ width: "100%" }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              ) : (
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  {subCategoryData.map((item, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      onClick={() => handleSelectSubCategory(item)}
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar src={item.image} alt="" />
                        </ListItemAvatar>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose1}>Cancel</Button>
            </DialogActions>
          </Dialog>

          {addNewProduct === true ? (
            <>
              <CustomTabPanel value={value} index={0}>
                <TextField
                  fullWidth
                  label="Product Name"
                  id="productname"
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                  required
                />
                <TextField
                  sx={{ marginTop: 3 }}
                  id="outlined-multiline-static"
                  label="Product short description"
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => setproductShortDesc(e.target.value)}
                  value={productShortDesc}
                  required
                />

                <Typography sx={{ marginTop: 3 }}>
                  Product more description
                </Typography>
                {longDesc.map((item, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      borderBottom: 2,
                      paddingBottom: 3,
                      borderBottomColor: "darkgray",
                      border: 1,
                      borderColor: "#C4C4C4",

                      padding: 2,
                    }}
                  >
                    <Paper elevation={0}>
                      {longDesc.length > 1 ? (
                        <IconButton onClick={() => removeDesc(idx)}>
                          <Delete color="error" />
                        </IconButton>
                      ) : null}
                      <TextField
                        id="title"
                        name="title"
                        value={item.title}
                        label="Title"
                        variant="outlined"
                        onChange={(e) => handleChangeTitle(e, idx)}
                        required
                        fullWidth
                      />

                      <TextField
                        sx={{ marginTop: 3, marginBottom: 3 }}
                        id="desc"
                        label="Product long description"
                        name="desc"
                        multiline
                        rows={5}
                        fullWidth
                        onChange={(e) => handleChangeDesc(e, idx)}
                        value={item.desc}
                        required
                      />
                      <input
                        accept="image/*"
                        name="descthumb"
                        id="descthumb"
                        // multiple
                        type="file"
                        onChange={(e) => handleChangeDescThumnail(e)}
                        style={{
                          display: "none",
                          width: "106px",
                          height: "106px",
                        }}
                      />
                      {loading1 === true && indexThumb === idx ? (
                        <Box sx={{ display: "flex" }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <label htmlFor="descthumb">
                          {item.descthumb !== "" ? (
                            <img
                              alt=""
                              src={item.descthumb}
                              style={{
                                width: "106px",
                                height: "106px",
                              }}
                            />
                          ) : (
                            <Button
                              component="span"
                              sx={{
                                backgroundColor: "#CEF4FF",
                                width: "125px",
                                height: "125px",
                                border: "dotted",
                                borderWidth: "2px",
                                borderColor: "blue",
                              }}
                              onClick={() => setIndexThumb(idx)}
                            >
                              <Add color="primary" />
                            </Button>
                          )}
                        </label>
                      )}
                      <Typography>Thumbnail image</Typography>
                    </Paper>
                  </Box>
                ))}
                <Box style={{ display: "flex", marginTop: "1vw" }}>
                  <Button
                    startIcon={
                      <Add
                        color="primary"
                        sx={{ backgroundColor: "#CEF4FF" }}
                      />
                    }
                    variant="text"
                    color="primary"
                    onClick={handleAddLongDesc}
                  >
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        textTransform: "none",
                      }}
                    >
                      Add more
                    </Typography>
                  </Button>
                </Box>

                <TextField
                  sx={{ marginTop: 3 }}
                  fullWidth
                  label="Product style code"
                  id="productstylecode"
                  onChange={(e) => setproductStyleCode(e.target.value)}
                  value={productStyleCode}
                  required
                />

                {tags.map((item, index) => (
                  <Autocomplete
                    key={index}
                    fullWidth
                    label="Tags"
                    multiple
                    onChange={(e, value) => {
                      setTagsValues((vals) => ({
                        ...vals,
                        [item]: { value, typeName: item },
                      }));
                    }}
                    // disabled={editing}
                    value={tagsValues[item]?.value ?? []}
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{ marginTop: 3 }}
                        id="outlined-basic"
                        label={item}
                        variant="outlined"
                        {...params}
                        required
                      />
                    )}
                  />
                ))}
                <TextField
                  sx={{ marginTop: 3 }}
                  fullWidth
                  label="Product brand"
                  id="productbrands"
                  onChange={(e) => setproductBrand(e.target.value)}
                  value={productBrand}
                  required
                />
                <FormControl sx={{ minWidth: "100%", marginTop: 3 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Select category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={categoryType}
                    onChange={(e) => setCategoryType(e.target.value)}
                    fullWidth
                    label="Select category"
                  >
                    <MenuItem value="None">
                      <em>None</em>
                    </MenuItem>
                    {subCategoryType.map((item, index) => (
                      <MenuItem value={item.name} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {fitData.length > 0 ? (
                  <FormControl sx={{ minWidth: "100%", marginTop: 3 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      Select fit*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={fit}
                      onChange={(e) => setFit(e.target.value)}
                      fullWidth
                      label="Select fit*"
                    >
                      <MenuItem value="None">
                        <em>None</em>
                      </MenuItem>
                      {fitData.map((item, index) => (
                        <MenuItem value={item.name} key={index}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null}
                {fabricData.length > 0 ? (
                  <FormControl sx={{ minWidth: "100%", marginTop: 3 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      Select fabric*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={fabric}
                      onChange={(e) => setFabric(e.target.value)}
                      fullWidth
                      label="Select fabric*"
                    >
                      <MenuItem value="None">
                        <em>None</em>
                      </MenuItem>
                      {fabricData.map((item, index) => (
                        <MenuItem value={item.name} key={index}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null}
                {attributes.length > 0 ? (
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        marginTop: 3,
                        marginBottom: 3,
                        width: "100%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Typography variant="h5">Attributes</Typography>

                    {attributes.filter((el) => el !== "Color").length > 0 ? (
                      attributes
                        .filter((el) => el !== "Color")
                        .map((item, index) => (
                          <Autocomplete
                            key={index}
                            fullWidth
                            label="Color"
                            multiple
                            onChange={(e, value) => {
                              setAttributeValues((vals) => ({
                                ...vals,
                                [item]: { value, typeName: item },
                              }));
                            }}
                            // disabled={editing}
                            value={attributeValues[item]?.value ?? []}
                            id="tags-filled"
                            options={[]}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  key={index}
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                id="outlined-basic"
                                label={item}
                                variant="outlined"
                                {...params}
                                required
                              />
                            )}
                          />
                        ))
                    ) : (
                      <Typography>No any attributes</Typography>
                    )}
                  </Box>
                ) : null}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid container gap={1}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Regular price"
                      sx={{ marginRight: "1%" }}
                      onChange={(e) =>
                        setproductBasePrice(
                          e.target.value < 0 ? 0 : e.target.value
                        )
                      }
                      value={productBasePrice}
                      required
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Sale price"
                      sx={{ marginRight: "1%" }}
                      onChange={(e) =>
                        setproductSalePrice(
                          e.target.value < 0 ? 0 : e.target.value
                        )
                      }
                      value={productSalePrice}
                      required
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Weight(in kg)"
                      sx={{ marginRight: "1%" }}
                      onChange={(e) =>
                        setproductWeight(
                          e.target.value < 0 ? 0 : e.target.value
                        )
                      }
                      value={productWeight}
                      required
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Height(in cm)"
                      sx={{ marginRight: "1%" }}
                      onChange={(e) =>
                        setproductHeight(
                          e.target.value < 0 ? 0 : e.target.value
                        )
                      }
                      value={productHeight}
                      required
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Length(in cm)"
                      sx={{ marginRight: "1%" }}
                      onChange={(e) =>
                        setproductLength(
                          e.target.value < 0 ? 0 : e.target.value
                        )
                      }
                      value={productLength}
                      required
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Width(in cm)"
                      onChange={(e) =>
                        setproductWidth(e.target.value < 0 ? 0 : e.target.value)
                      }
                      value={productWidth}
                      required
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                </Grid>

                <Typography variant="h5" sx={{ marginTop: 3 }}>
                  Color
                </Typography>
                {variants.map((item, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",

                      borderBottom: 2,
                      paddingBottom: 3,
                      borderBottomColor: "darkgray",
                    }}
                  >
                    <Grid container sx={{ marginTop: 3 }}>
                      <Grid item xs={12} md={4}>
                        <Paper elevation={0} sx={{ marginBottom: 2 }}>
                          {variants.length > 1 ? (
                            <IconButton onClick={() => removeVariant(idx)}>
                              <Delete color="error" />
                            </IconButton>
                          ) : null}
                          <TextField
                            id="value"
                            name="value"
                            value={item.value}
                            label="Value"
                            variant="outlined"
                            onChange={(e) => handleChangeValue(e, idx)}
                            required
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Paper elevation={0}>
                          <input
                            accept="image/*"
                            name="thumb"
                            id="thumb"
                            // multiple
                            type="file"
                            onChange={(e) => handleChangeThumnail(e)}
                            style={{
                              display: "none",
                              width: "106px",
                              height: "106px",
                            }}
                          />

                          {loading1 === true && indexThumb === idx ? (
                            <Box sx={{ display: "flex" }}>
                              <CircularProgress />
                            </Box>
                          ) : (
                            <label htmlFor="thumb">
                              {item.thumb !== "" ? (
                                <img
                                  alt=""
                                  src={item.thumb}
                                  style={{
                                    width: "106px",
                                    height: "106px",
                                  }}
                                />
                              ) : (
                                <Button
                                  component="span"
                                  sx={{
                                    backgroundColor: "#CEF4FF",
                                    width: "125px",
                                    height: "125px",
                                    border: "dotted",
                                    borderWidth: "2px",
                                    borderColor: "blue",
                                  }}
                                  onClick={() => setIndexThumb(idx)}
                                >
                                  <Add color="primary" />
                                </Button>
                              )}
                            </label>
                          )}

                          <Typography>Thumbnail image</Typography>
                        </Paper>
                      </Grid>
                    </Grid>

                    {item?.gallery?.map((el, index) => (
                      <Paper
                        elevation={0}
                        key={index}
                        sx={{ marginRight: 2, marginTop: 2 }}
                      >
                        <input
                          accept="image/*"
                          name="gallery"
                          id="gallery"
                          // multiple
                          type="file"
                          onChange={(e) => handleChangeGallery(e)}
                          style={{
                            display: "none",
                            width: "106px",
                            height: "106px",
                          }}
                        />

                        {loading2 === true && indexThumb === idx ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <label htmlFor="gallery">
                            {el.link !== "" ? (
                              <img
                                alt=""
                                src={el.link}
                                style={{
                                  width: "106px",
                                  height: "106px",
                                }}
                              />
                            ) : (
                              <Button
                                component="span"
                                sx={{
                                  backgroundColor: "#CEF4FF",
                                  width: "125px",
                                  height: "125px",
                                  border: "dotted",
                                  borderWidth: "2px",
                                  borderColor: "blue",
                                }}
                                onClick={() => setIndexThumb(idx)}
                              >
                                <Add color="primary" />
                              </Button>
                            )}

                            {index === 0 ? null : (
                              <Button
                                startIcon={
                                  <Remove
                                    color="primary"
                                    sx={{ backgroundColor: "#CEF4FF" }}
                                  />
                                }
                                variant="text"
                                color="primary"
                                onClick={() => removeGalleryImage(idx, index)}
                              ></Button>
                            )}
                          </label>
                        )}

                        <Typography>
                          Gallery image {index === 0 ? null : "(" + index + ")"}
                        </Typography>
                      </Paper>
                    ))}
                  </Box>
                ))}
                <Box style={{ display: "flex", marginTop: "1vw" }}>
                  <Button
                    startIcon={
                      <Add
                        color="primary"
                        sx={{ backgroundColor: "#CEF4FF" }}
                      />
                    }
                    variant="text"
                    color="primary"
                    onClick={handleAddVariants}
                  >
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        textTransform: "none",
                      }}
                    >
                      Add variant
                    </Typography>
                  </Button>
                </Box>
              </CustomTabPanel>
            </>
          ) : (
            <Typography variant="h5">
              Ooops no any category selected!
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
