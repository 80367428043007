import * as React from "react";

import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import { Typography, Button } from "@mui/material";
import {
  collection,
  addDoc,
  query,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

export default function Policies() {
  const [privacyPolicy, setPrivacyPolicy] = React.useState("");
  const [termsAndCondition, setTermsAndCondition] = React.useState("");
  const [shippingPolicy, setShippingPolicy] = React.useState("");
  const [refundReturn, setRefundReturn] = React.useState("");
  const [exchangePolicy, setExchangePolicy] = React.useState("");
  const [policyId, setPolicyId] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getPolicies();
  }, []);

  const getPolicies = async () => {
    const q = query(collection(db, "website_policies"));
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (newData.length > 0) {
        setPolicyId(newData[0]?.id);
        setPrivacyPolicy(newData[0]?.privacyPolicy);
        setTermsAndCondition(newData[0]?.termsAndCondition);
        setShippingPolicy(newData[0]?.shippingPolicy);
        setRefundReturn(newData[0]?.refundReturn);
        setExchangePolicy(newData[0]?.exchangePolicy);
      }
      console.log({ newData });
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await updateDoc(doc(db, "website_policies", policyId), {
      privacyPolicy: privacyPolicy,
      termsAndCondition: termsAndCondition,
      shippingPolicy: shippingPolicy,
      refundReturn: refundReturn,
      exchangePolicy: exchangePolicy,
    });
    setLoading(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Website policies" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                marginRight: "2vw",
                marginBottom: "2vw",
              }}
              onClick={handleSubmit}
              disabled={loading === true ? true : false}
            >
              {loading === false ? "Submit" : "Please wait..."}
            </Button>
          </Box>
        </Box>
        <Typography sx={{ fontWeight: "600", marginTop: 3, fontSize: 20 }}>
          Privacy Policy
        </Typography>
        <Box sx={{ width: "100%" }}>
          <div style={{ display: "grid", justifyContent: "center" }}>
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="write your content ...."
              onChange={(content) => setPrivacyPolicy(content)}
              style={{ height: "220px" }}
              value={privacyPolicy}
            ></ReactQuill>
          </div>
        </Box>

        <Typography sx={{ fontWeight: "600", marginTop: 10, fontSize: 20 }}>
          Terms & Condition
        </Typography>
        <Box sx={{ width: "100%" }}>
          <div style={{ display: "grid", justifyContent: "center" }}>
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="write your content ...."
              onChange={(content) => setTermsAndCondition(content)}
              style={{ height: "220px" }}
              value={termsAndCondition}
            ></ReactQuill>
          </div>
        </Box>

        <Typography sx={{ fontWeight: "600", marginTop: 10, fontSize: 20 }}>
          Shipping & Delivery
        </Typography>
        <Box sx={{ width: "100%" }}>
          <div style={{ display: "grid", justifyContent: "center" }}>
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="write your content ...."
              onChange={(content) => setShippingPolicy(content)}
              style={{ height: "220px" }}
              value={shippingPolicy}
            ></ReactQuill>
          </div>
        </Box>

        <Typography sx={{ fontWeight: "600", marginTop: 10, fontSize: 20 }}>
          Refund & Return Policy
        </Typography>
        <Box sx={{ width: "100%" }}>
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            placeholder="write your content ...."
            onChange={(content) => setRefundReturn(content)}
            style={{ height: "220px" }}
            value={refundReturn}
          ></ReactQuill>
        </Box>

        <Typography sx={{ fontWeight: "600", marginTop: 10, fontSize: 20 }}>
          Exchange Policy
        </Typography>
        <Box sx={{ width: "100%" }}>
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            placeholder="write your content ...."
            onChange={(content) => setExchangePolicy(content)}
            style={{ height: "220px" }}
            value={exchangePolicy}
          ></ReactQuill>
        </Box>
      </Box>
    </Box>
  );
}
