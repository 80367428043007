import * as React from "react";

import Box from "@mui/material/Box";
import { Grid, IconButton, Paper, Typography } from "@mui/material";

import TextField from "@mui/material/TextField";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";

import Button from "@mui/material/Button";

import { collection, query, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";
import { Add, Delete } from "@mui/icons-material";

export default function AboutUs() {
  let userdetail = JSON.parse(localStorage.getItem("userdetail"));

  const [bannerImage, setBannerImage] = React.useState("");
  const [seconSectionImage, setSecondSectionImage] = React.useState("");
  const [videoLink, setVideoLink] = React.useState("");
  const [aboutUsId, setAboutUsID] = React.useState("");
  const [indexThumb, setIndexThumb] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [firstSection, setFirstSection] = React.useState([
    { mainTitle: "", mainSubtitle: "", mainDesc: "" },
    { firstSectionTitle: "", firstSectionDesc: "" },
    {
      firstSectionHoverTitle: "",
      firstSectionHoverDesc: "",
      firstSectionHoverLink: "",
    },
    { secondSectionTitle: "", secondSectionDesc: "" },
    {
      secondSectionHoverTitle: "",
      secondSectionHoverDesc: "",
      secondSectionHoverLink: "",
    },
  ]);

  const [secondSection, setSecondSection] = React.useState([
    { mainTitle: "", mainSubtitle: "", mainDesc: "" },
  ]);

  const [teamSection, setTeamSection] = React.useState([
    {
      name: "",
      position: "",
      imageLink: "",
      facebookLink: "",
      twitterLink: "",
      instaLink: "",
    },
  ]);

  const handleChangeBannerImage = async (e) => {
    setLoading(true);
    if (e.target.files[0]) {
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_aboutus_images_" +
          userdetail[0]?.id +
          "/" +
          e.target.files[0]?.name
      );

      await uploadBytes(mountainImagesRef, e.target.files[0]).then(
        async (snapshot) => {
          await getDownloadURL(
            ref(
              storage,
              "dashboard_aboutus_images_" +
                userdetail[0]?.id +
                "/" +
                e.target.files[0]?.name
            )
          ).then((url) => {
            setBannerImage(url);
          });
        }
      );
    }
    setLoading(false);
  };

  const handleChangeSecondSectionImage = async (e) => {
    setLoading(true);
    if (e.target.files[0]) {
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_aboutus_images_" +
          userdetail[0]?.id +
          "/" +
          e.target.files[0]?.name
      );

      await uploadBytes(mountainImagesRef, e.target.files[0]).then(
        async (snapshot) => {
          await getDownloadURL(
            ref(
              storage,
              "dashboard_aboutus_images_" +
                userdetail[0]?.id +
                "/" +
                e.target.files[0]?.name
            )
          ).then((url) => {
            setSecondSectionImage(url);
          });
        }
      );
    }
    setLoading(false);
  };

  const handleChangeFirstSection = (e, idx) => {
    const { name, value } = e.target;

    const list = [...firstSection];

    list[idx][name] = value;

    setFirstSection(list);
  };

  const handleChangeSecondSection = (e, idx) => {
    const { name, value } = e.target;

    const list = [...secondSection];

    list[idx][name] = value;

    setSecondSection(list);
  };

  const handleChangeTeamSection = (e, idx) => {
    const { name, value } = e.target;

    const list = [...teamSection];

    list[idx][name] = value;

    setTeamSection(list);
  };

  const handleAddTeam = () => {
    setTeamSection([
      ...teamSection,
      {
        name: "",
        position: "",
        imageLink: "",
        facebookLink: "",
        twitterLink: "",
        instaLink: "",
      },
    ]);
  };

  const removeDesc = (ii) => {
    const list = [...teamSection];

    list.splice(ii, 1);

    setTeamSection(list);
  };

  const handleChangeTeamThumnail = async (e) => {
    setLoading(true);
    if (e.target.files[0]) {
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_aboutus_images_" +
          userdetail[0]?.id +
          "/" +
          e.target.files[0]?.name
      );

      await uploadBytes(mountainImagesRef, e.target.files[0]).then(
        async (snapshot) => {
          await getDownloadURL(
            ref(
              storage,
              "dashboard_aboutus_images_" +
                userdetail[0]?.id +
                "/" +
                e.target.files[0]?.name
            )
          ).then((url) => {
            const { name } = e.target;
            const list = [...teamSection];
            list[indexThumb][name] = url;
            setTeamSection(list);
          });
        }
      );
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await updateDoc(doc(db, "website_aboutus", aboutUsId), {
      bannerImage: bannerImage,
      firstSection: firstSection,
      secondSection: secondSection,
      seconSectionImage: seconSectionImage,
      teamSection: teamSection,
      videoLink: videoLink,
      editedDate: new Date().toString(),
    });

    setLoading(false);
  };

  React.useEffect(() => {
    getAboutUsData();
  }, []);

  const getAboutUsData = async () => {
    const q = query(collection(db, "website_aboutus"));
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      console.log({ newData });

      if (newData.length > 0) {
        setBannerImage(newData[0]?.bannerImage);
        setFirstSection(newData[0]?.firstSection);
        setSecondSection(newData[0]?.secondSection);
        setSecondSectionImage(newData[0]?.seconSectionImage);
        setTeamSection(newData[0]?.teamSection);
        setVideoLink(newData[0]?.videoLink);
        setAboutUsID(newData[0]?.id);
      }
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="About us" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginRight: "2vw",
                  marginBottom: "2vw",
                }}
                onClick={handleSubmit}
                disabled={loading === true ? true : false}
              >
                {loading === false ? "Submit" : "Please wait..."}
              </Button>
            </Box>
          </Box>

          <input
            accept="image/*"
            name="bannerImage"
            id="bannerImage"
            type="file"
            onChange={(e) => handleChangeBannerImage(e)}
            style={{
              display: "none",
              width: "106px",
              height: "106px",
            }}
          />
          {loading === true ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <label htmlFor="bannerImage">
              <Button
                variant="contained"
                component="span"
                size="large"
                sx={{
                  marginRight: "2vw",
                  marginBottom: "1vw",
                  marginTop: "2vw",
                }}
                disabled={loading === true ? true : false}
              >
                {loading === false ? "Edit" : "Please wait..."}
              </Button>
              <Typography sx={{ fontWeight: "600", fontSize: 20 }}>
                Banner Image
              </Typography>
              <img
                alt=""
                src={bannerImage}
                style={{
                  width: "100%",
                  height: "50%",
                }}
              />
            </label>
          )}
          <Typography sx={{ fontWeight: "600", marginTop: 3, fontSize: 20 }}>
            About Us First Section
          </Typography>
          <Grid
            container
            sx={{
              borderBottom: 2,
              borderBottomColor: "darkgray",
              border: 1,
              borderColor: "#C4C4C4",
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                p: 1,
              },
            }}
          >
            <Grid item xs={12} md={6}>
              <Paper elevation={0}>
                <TextField
                  value={firstSection[0].mainSubtitle}
                  name="mainSubtitle"
                  label="Sub Title"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChangeFirstSection(e, 0)}
                />
                <TextField
                  value={firstSection[0].mainTitle}
                  name="mainTitle"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 3 }}
                  onChange={(e) => handleChangeFirstSection(e, 0)}
                />

                <TextField
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  label="Description"
                  multiline
                  rows={5}
                  fullWidth
                  value={firstSection[0].mainDesc}
                  name="mainDesc"
                  onChange={(e) => handleChangeFirstSection(e, 0)}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={0}>
                <TextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 3 }}
                  value={firstSection[1].firstSectionTitle}
                  name="firstSectionTitle"
                  onChange={(e) => handleChangeFirstSection(e, 1)}
                />

                <TextField
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  label="Description"
                  multiline
                  rows={5}
                  fullWidth
                  value={firstSection[1].firstSectionDesc}
                  name="firstSectionDesc"
                  onChange={(e) => handleChangeFirstSection(e, 1)}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={0}>
                <TextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 3 }}
                  value={firstSection[3].secondSectionTitle}
                  name="secondSectionTitle"
                  onChange={(e) => handleChangeFirstSection(e, 3)}
                />

                <TextField
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  label="Description"
                  multiline
                  rows={5}
                  fullWidth
                  value={firstSection[3].secondSectionDesc}
                  name="secondSectionDesc"
                  onChange={(e) => handleChangeFirstSection(e, 3)}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={0}>
                <TextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 3 }}
                  value={firstSection[2].firstSectionHoverTitle}
                  name="firstSectionHoverTitle"
                  onChange={(e) => handleChangeFirstSection(e, 2)}
                />

                <TextField
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  label="Description"
                  multiline
                  rows={5}
                  fullWidth
                  value={firstSection[2].firstSectionHoverDesc}
                  name="firstSectionHoverDesc"
                  onChange={(e) => handleChangeFirstSection(e, 2)}
                />
                <TextField
                  label="Button link"
                  variant="outlined"
                  fullWidth
                  value={firstSection[2].firstSectionHoverLink}
                  name="firstSectionHoverLink"
                  onChange={(e) => handleChangeFirstSection(e, 2)}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={0}>
                <TextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 3 }}
                  value={firstSection[4].secondSectionHoverTitle}
                  name="secondSectionHoverTitle"
                  onChange={(e) => handleChangeFirstSection(e, 4)}
                />

                <TextField
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  label="Description"
                  multiline
                  rows={5}
                  fullWidth
                  value={firstSection[4].secondSectionHoverDesc}
                  name="secondSectionHoverDesc"
                  onChange={(e) => handleChangeFirstSection(e, 4)}
                />
                <TextField
                  label="Button link"
                  variant="outlined"
                  fullWidth
                  value={firstSection[4].secondSectionHoverLink}
                  name="secondSectionHoverLink"
                  onChange={(e) => handleChangeFirstSection(e, 4)}
                />
              </Paper>
            </Grid>
          </Grid>

          <Typography sx={{ fontWeight: "600", marginTop: 3, fontSize: 20 }}>
            About Us Second Section
          </Typography>
          <Grid
            container
            sx={{
              borderBottom: 2,
              borderBottomColor: "darkgray",
              border: 1,
              borderColor: "#C4C4C4",
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                p: 1,
              },
            }}
          >
            <Grid item xs={12} md={6}>
              <Paper elevation={0}>
                <TextField
                  value={secondSection[0].mainSubtitle}
                  name="mainSubtitle"
                  label="Sub Title"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChangeSecondSection(e, 0)}
                />
                <TextField
                  value={secondSection[0].mainTitle}
                  name="mainTitle"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 3 }}
                  onChange={(e) => handleChangeSecondSection(e, 0)}
                />

                <TextField
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  label="Description"
                  multiline
                  rows={5}
                  fullWidth
                  value={secondSection[0].mainDesc}
                  name="mainDesc"
                  onChange={(e) => handleChangeSecondSection(e, 0)}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={0}>
                <input
                  accept="image/*"
                  name="secondSectionImage"
                  id="secondSectionImage"
                  type="file"
                  onChange={(e) => handleChangeSecondSectionImage(e)}
                  style={{
                    display: "none",
                    width: "106px",
                    height: "106px",
                  }}
                />
                {loading === true ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <label htmlFor="secondSectionImage">
                    <Button
                      variant="contained"
                      component="span"
                      size="large"
                      sx={{
                        marginRight: "2vw",
                        marginBottom: "1vw",
                      }}
                      disabled={loading === true ? true : false}
                    >
                      {loading === false ? "Edit" : "Please wait..."}
                    </Button>
                    <Typography sx={{ fontWeight: "600", fontSize: 20 }}>
                      Image
                    </Typography>
                    <img
                      alt=""
                      src={seconSectionImage}
                      style={{
                        width: "300px",
                        height: "300px",
                      }}
                    />
                  </label>
                )}
              </Paper>
            </Grid>
          </Grid>

          <Typography sx={{ fontWeight: "600", marginTop: 3, fontSize: 20 }}>
            About Us Team Section
          </Typography>
          <Grid
            container
            sx={{
              borderBottom: 2,
              borderBottomColor: "darkgray",
              border: 1,
              borderColor: "#C4C4C4",
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                p: 1,
              },
            }}
          >
            {teamSection.map((item, idx) => (
              <Grid
                key={idx}
                item
                xs={12}
                md={6}
                sx={{
                  borderBottom: 2,
                  paddingBottom: 3,
                  borderBottomColor: "darkgray",
                  border: 1,
                  borderColor: "#C4C4C4",
                }}
              >
                <Paper elevation={0}>
                  {teamSection.length > 1 ? (
                    <IconButton onClick={() => removeDesc(idx)}>
                      <Delete color="error" />
                    </IconButton>
                  ) : null}
                  <TextField
                    name="name"
                    value={item.name}
                    label="Name"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChangeTeamSection(e, idx)}
                  />
                  <TextField
                    name="position"
                    value={item.position}
                    label="Position"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    onChange={(e) => handleChangeTeamSection(e, idx)}
                  />
                  <TextField
                    name="facebookLink"
                    value={item.facebookLink}
                    label="Facebook Link"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 1 }}
                    onChange={(e) => handleChangeTeamSection(e, idx)}
                  />
                  <TextField
                    name="twitterLink"
                    value={item.twitterLink}
                    label="Twitter Link"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 1 }}
                    onChange={(e) => handleChangeTeamSection(e, idx)}
                  />
                  <TextField
                    name="instaLink"
                    value={item.instaLink}
                    label="Insta Link"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 1 }}
                    onChange={(e) => handleChangeTeamSection(e, idx)}
                  />
                  <input
                    accept="image/*"
                    name="imageLink"
                    id="imageLink"
                    type="file"
                    style={{
                      display: "none",
                      width: "106px",
                      height: "106px",
                    }}
                    onChange={(e) => handleChangeTeamThumnail(e)}
                  />
                  {loading === true ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <label htmlFor="imageLink">
                      {item.imageLink !== "" ? (
                        <img
                          alt=""
                          src={item.imageLink}
                          style={{
                            width: "106px",
                            height: "106px",
                          }}
                        />
                      ) : (
                        <Button
                          component="span"
                          sx={{
                            backgroundColor: "#CEF4FF",
                            width: "125px",
                            height: "125px",
                            border: "dotted",
                            borderWidth: "2px",
                            borderColor: "blue",
                          }}
                          onClick={() => setIndexThumb(idx)}
                        >
                          <Add color="primary" />
                        </Button>
                      )}
                    </label>
                  )}
                  <Typography>Image</Typography>
                </Paper>
              </Grid>
            ))}
            <Box style={{ display: "flex", marginTop: "1vw" }}>
              <Button
                startIcon={
                  <Add color="primary" sx={{ backgroundColor: "#CEF4FF" }} />
                }
                variant="text"
                color="primary"
                onClick={handleAddTeam}
              >
                <Typography
                  sx={{
                    textDecoration: "underline",
                    textTransform: "none",
                  }}
                >
                  Add more
                </Typography>
              </Button>
            </Box>
          </Grid>

          <Typography sx={{ fontWeight: "600", marginTop: 3, fontSize: 20 }}>
            About Us Video Link
          </Typography>
          <Grid
            container
            sx={{
              borderBottom: 2,
              borderBottomColor: "darkgray",
              border: 1,
              borderColor: "#C4C4C4",
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                p: 1,
              },
            }}
          >
            <Grid item xs={12} md={12}>
              <Paper elevation={0}>
                <TextField
                  value={videoLink}
                  label="Video Link"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setVideoLink(e.target.value)}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
