import * as React from "react";

import Box from "@mui/material/Box";
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";

import Button from "@mui/material/Button";
import { Add, Delete, Remove } from "@mui/icons-material";
import {
  updateDoc,
  doc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useLocation } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProductEdit() {
  const navigate = useNavigate();
  const location = useLocation();

  let userdetail = JSON.parse(localStorage.getItem("userdetail"));

  const [value, setValue] = React.useState(0);

  const [productName, setProductName] = React.useState("");
  const [productShortDesc, setproductShortDesc] = React.useState("");
  const [productLongDesc, setproductLongDesc] = React.useState([]);
  const [productStyleCode, setproductStyleCode] = React.useState("");
  const [productBrand, setproductBrand] = React.useState("");
  const [productBasePrice, setproductBasePrice] = React.useState("");
  const [productSalePrice, setproductSalePrice] = React.useState("");
  const [productHeight, setproductHeight] = React.useState("");
  const [productWeight, setproductWeight] = React.useState("");
  const [categoryType, setCategoryType] = React.useState("");
  const [fabric, setFabric] = React.useState("");
  const [fit, setFit] = React.useState("");

  const [productWidth, setproductWidth] = React.useState("");
  const [productLength, setproductLength] = React.useState("");

  const [attributes, setAttributes] = React.useState([]);
  const [attributeValues, setAttributeValues] = React.useState([]);
  const [tags] = React.useState(["Tags"]);
  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [rejectedOpen, setRejectOpen] = React.useState(false);
  const [fitData, setFitData] = React.useState([]);
  const [fabricData, setFabricData] = React.useState([]);
  const [rejectedMsg, setRejectedMsg] = React.useState([]);
  const [rejectmsg, setRejectmsg] = React.useState("");
  const [subCategoryType, setSubCategoryType] = React.useState([]);

  const [tagsValues, setTagsValues] = React.useState([]);
  const [indexThumb, setIndexThumb] = React.useState(0);
  const [randomNumber, setRandomNumber] = React.useState(new Date());

  const [variants, setVariants] = React.useState([
    {
      value: "",
      thumb: "",
      gallery: [{ order: 0, link: "" }],
      qty: 0,
      sku: "",
    },
  ]);

  const [longDesc, setLongDesc] = React.useState([
    {
      title: "",
      descthumb: "",
      desc: "",
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  React.useEffect(() => {
    setVariants(location.state.data.variants);
    setproductLength(location.state.data.productLength);
    setproductWidth(location.state.data.productWidth);
    setproductHeight(location.state.data.productHeight);
    setproductWeight(location.state.data.productWeight);
    setproductBasePrice(location.state.data.productBasePrice);
    setproductSalePrice(location.state.data.productSalePrice);
    setAttributeValues(location.state.data.attributes);
    setAttributes(location.state.data.category.attributes);
    setproductBrand(location.state.data.productBrand);
    setTagsValues(location.state.data.tags);
    setProductName(location.state.data.productName);
    setproductShortDesc(location.state.data.productShortDesc);
    setLongDesc(location.state.data.productLongDesc);
    setproductStyleCode(location.state.data.productStyleCode);
    setRejectedMsg(location.state.data.rejectedMsg);

    console.log(location.state.data);

    setCategoryType(location.state.data.categoryType);
    setFit(location.state.data.fit);
    setFabric(location.state.data.fabric);
    getFit();
    getFabric();
    getSubCategoryType();
  }, [location]);

  const getSubCategoryType = async () => {
    // setLoading(true);
    const q = query(
      collection(db, "dashboard_categories"),
      where("parent", "==", location.state.data.category.name)
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      console.log({ newData });

      setSubCategoryType(newData);
    });
  };

  const getFit = async () => {
    const q = query(
      collection(db, "dashboard_fit")
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setFitData(newData);
    });
  };

  const getFabric = async (e) => {
    const q = query(
      collection(db, "dashboard_fabric")
    );
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setFabricData(newData);
    });
  };

  const handleAddVariants = () => {
    setRandomNumber(randomNumber + 1);
    setVariants([
      ...variants,
      {
        value: "",
        thumb: "",
        gallery: [{ order: 0, link: "" }],
        qty: 0,
        sku: "",
      },
    ]);
  };

  const handleChangeThumnail = async (e) => {
    setLoading1(true);
    const storage = getStorage();

    const mountainImagesRef = ref(
      storage,
      "dashboard_product_images_" +
        userdetail[0]?.id +
        "/" +
        e.target.files[0].name
    );

    await uploadBytes(mountainImagesRef, e.target.files[0]).then(
      async (snapshot) => {
        await getDownloadURL(
          ref(
            storage,
            "dashboard_product_images_" +
              userdetail[0]?.id +
              "/" +
              e.target.files[0].name
          )
        ).then((url) => {
          const { name } = e.target;

          const list = [...variants];

          list[indexThumb][name] = url;
          setVariants(list);
          // setFile(e.target.files[0]);
        });
      }
    );
    setLoading1(false);
  };

  const handleChangeGallery = async (e) => {
    setLoading2(true);
    const storage = getStorage();

    const mountainImagesRef = ref(
      storage,
      "dashboard_product_images_" +
        userdetail[0]?.id +
        "/" +
        e.target.files[0].name
    );

    await uploadBytes(mountainImagesRef, e.target.files[0]).then(
      async (snapshot) => {
        await getDownloadURL(
          ref(
            storage,
            "dashboard_product_images_" +
              userdetail[0]?.id +
              "/" +
              e.target.files[0].name
          )
        ).then((url) => {
          const list = [...variants];

          let data = {
            order:
              variants[indexThumb].gallery[
                variants[indexThumb].gallery.length - 1
              ].order + 1,

            link: url,
          };

          list[indexThumb].gallery.push(data);

          setVariants(list);
        });
      }
    );
    setLoading2(false);
  };

  const handleChangeValue = (e, idx) => {
    const { name, value } = e.target;

    const list = [...variants];

    list[idx][name] = value;

    setVariants(list);
  };

  const removeGalleryImage = (ii, i) => {
    const list = [...variants];

    list[ii].gallery.splice(i, 1);

    setVariants(list);
  };

  const handleSubmit = async (id) => {
    if (
      productName !== "" &&
      productShortDesc !== "" &&
      productLongDesc !== "" &&
      productStyleCode !== "" &&
      tagsValues.Tags.value.length > 0 &&
      productBrand !== "" &&
      productBasePrice !== "" &&
      productSalePrice !== "" &&
      productLength !== "" &&
      productWeight !== "" &&
      productWidth !== "" &&
      variants.map((el) => el.gallery.length > 1)
    ) {
      setLoading(true);

      await updateDoc(doc(db, "dashboard_product", id), {
        productName: productName,
        productShortDesc: productShortDesc,
        productLongDesc: longDesc,
        productStyleCode: productStyleCode,
        tags: tagsValues,
        productBrand: productBrand,
        attributes: attributeValues,
        productSalePrice: productSalePrice,
        productHeight: productHeight,
        productWeight: productWeight,
        productWidth: productWidth,
        productLength: productLength,
        variants: variants,
        status: false,
        qaChecked: false,
        drafted: false,
        categoryType: categoryType,
        fit: fit,
        fabric: fabric,
        editedDate: new Date().toString(),
      });

      navigate("/productlist");
    } else {
      setOpen2(true);
    }
  };

  const updateProductStatus = async (id, status) => {
    await updateDoc(doc(db, "dashboard_product", id), {
      qaChecked: status,
    });
    navigate("/qaproduct");
  };

  const handleReject = async (id) => {
    setLoading(true);

    await updateDoc(doc(db, "dashboard_product", id), {
      isRejected: true,
      rejectedMsg: [
        ...rejectedMsg,
        { date: new Date().toString(), msg: rejectmsg, qaId: userdetail[0].id },
      ],
      status: false,
      qaChecked: true,
      isNew: false,
    });

    navigate("/qaproduct");
  };

  const handleChangeDescThumnail = async (e) => {
    setLoading1(true);
    if (e.target.files[0]) {
      const storage = getStorage();

      const mountainImagesRef = ref(
        storage,
        "dashboard_product_images_" +
          userdetail[0]?.id +
          "/" +
          e.target.files[0]?.name
      );

      await uploadBytes(mountainImagesRef, e.target.files[0]).then(
        async (snapshot) => {
          await getDownloadURL(
            ref(
              storage,
              "dashboard_product_images_" +
                userdetail[0]?.id +
                "/" +
                e.target.files[0]?.name
            )
          ).then((url) => {
            const { name } = e.target;

            const list = [...longDesc];

            list[indexThumb][name] = url;
            setLongDesc(list);
          });
        }
      );
    }
    setLoading1(false);
  };

  const handleAddLongDesc = () => {
    setLongDesc([
      ...longDesc,
      {
        title: "",
        descthumb: "",
        desc: "",
      },
    ]);
  };

  const handleChangeTitle = (e, idx) => {
    const { name, value } = e.target;

    const list = [...longDesc];

    list[idx][name] = value;

    setLongDesc(list);
  };

  const handleChangeDesc = (e, idx) => {
    const { name, value } = e.target;

    const list = [...longDesc];

    list[idx][name] = value;

    setLongDesc(list);
  };

  const removeDesc = (ii) => {
    const list = [...longDesc];

    list.splice(ii, 1);

    setLongDesc(list);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="Product edit" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ width: "100%" }}>
          <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
            <Alert
              onClose={handleClose2}
              severity="error"
              sx={{ width: "100%" }}
            >
              Please fill all required fields!
            </Alert>
          </Snackbar>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Product data" {...a11yProps(0)} />
              <Tab label="Variations" {...a11yProps(1)} />
            </Tabs>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              {userdetail[0]?.role !== "qa" ? (
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    marginRight: "2vw",
                  }}
                  onClick={() => handleSubmit(location.state.data.id)}
                  disabled={loading === true ? true : false}
                >
                  {loading === false ? "Submit" : "Please wait..."}
                </Button>
              ) : null}

              {userdetail[0]?.role === "qa" ? (
                <>
                  <Button
                    size="large"
                    variant="contained"
                    onClick={() =>
                      updateProductStatus(
                        location.state.data.id,
                        location.state.data.qaChecked === true ? false : true
                      )
                    }
                    sx={{
                      marginRight: "2vw",
                    }}
                    color="success"
                  >
                    {loading === false ? "Publish" : "Please wait..."}
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    onClick={() => setRejectOpen(true)}
                    sx={{
                      marginRight: "2vw",
                    }}
                    color="error"
                  >
                    Reject
                  </Button>
                  <Dialog
                    open={rejectedOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title" sx={{ width: 500 }}>
                      {"Reason for reject"}
                    </DialogTitle>
                    <DialogContent>
                      <TextField
                        multiline
                        rows={10}
                        onChange={(e) => setRejectmsg(e.target.value)}
                        sx={{ width: 500 }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => handleReject(location.state.data.id)}
                        variant="contained"
                        disabled={loading === true ? true : false}
                      >
                        {loading === true ? "Please wait..." : "Confirm"}
                      </Button>
                      <Button onClick={() => setRejectOpen(false)}>
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              ) : null}

              <Button
                size="large"
                variant="outlined"
                onClick={() => navigate("/productlist")}
              >
                Cancel
              </Button>
            </Box>
          </Box>

          <>
            <CustomTabPanel value={value} index={0}>
              <TextField
                fullWidth
                label="Product Name"
                id="productname"
                onChange={(e) => setProductName(e.target.value)}
                value={productName}
                required
                InputProps={{
                  readOnly: userdetail[0]?.role === "qa" ? true : false,
                }}
              />
              <TextField
                sx={{ marginTop: 3 }}
                id="outlined-multiline-static"
                label="Product short description"
                multiline
                rows={4}
                fullWidth
                onChange={(e) => setproductShortDesc(e.target.value)}
                value={productShortDesc}
                required
                InputProps={{
                  readOnly: userdetail[0]?.role === "qa" ? true : false,
                }}
              />

              {longDesc.map((item, idx) => (
                <Box
                  key={idx}
                  sx={{
                    borderBottom: 2,
                    paddingBottom: 3,
                    borderBottomColor: "darkgray",
                    border: 1,
                    marginTop: 3,
                    padding: 2,
                  }}
                >
                  <Paper elevation={0}>
                    {longDesc.length > 1 ? (
                      <IconButton onClick={() => removeDesc(idx)}>
                        <Delete color="error" />
                      </IconButton>
                    ) : null}
                    <TextField
                      id="title"
                      name="title"
                      value={item.title}
                      label="Title"
                      variant="outlined"
                      onChange={(e) => handleChangeTitle(e, idx)}
                      required
                      fullWidth
                      InputProps={{
                        readOnly: userdetail[0]?.role === "qa" ? true : false,
                      }}
                    />

                    <TextField
                      sx={{ marginTop: 3, marginBottom: 3 }}
                      id="desc"
                      label="Product long description"
                      name="desc"
                      multiline
                      rows={5}
                      fullWidth
                      onChange={(e) => handleChangeDesc(e, idx)}
                      value={item.desc}
                      required
                      InputProps={{
                        readOnly: userdetail[0]?.role === "qa" ? true : false,
                      }}
                    />
                    <input
                      accept="image/*"
                      name="descthumb"
                      id="descthumb"
                      // multiple
                      type="file"
                      onChange={(e) => handleChangeDescThumnail(e)}
                      style={{
                        display: "none",
                        width: "106px",
                        height: "106px",
                      }}
                      disabled={userdetail[0]?.role === "qa" ? true : false}
                    />
                    {loading1 === true && indexThumb === idx ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <label htmlFor="descthumb">
                        {item.descthumb !== "" ? (
                          <img
                            alt=""
                            src={item.descthumb}
                            style={{
                              width: "106px",
                              height: "106px",
                            }}
                          />
                        ) : (
                          <Button
                            component="span"
                            sx={{
                              backgroundColor: "#CEF4FF",
                              width: "125px",
                              height: "125px",
                              border: "dotted",
                              borderWidth: "2px",
                              borderColor: "blue",
                            }}
                            onClick={() => setIndexThumb(idx)}
                            disabled={
                              userdetail[0]?.role === "qa" ? true : false
                            }
                          >
                            <Add color="primary" />
                          </Button>
                        )}
                      </label>
                    )}
                    <Typography>Thumbnail image</Typography>
                  </Paper>
                </Box>
              ))}
              <Box style={{ display: "flex", marginTop: "1vw" }}>
                <Button
                  startIcon={
                    <Add color="primary" sx={{ backgroundColor: "#CEF4FF" }} />
                  }
                  variant="text"
                  color="primary"
                  onClick={handleAddLongDesc}
                  disabled={userdetail[0]?.role === "qa" ? true : false}
                >
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      textTransform: "none",
                    }}
                  >
                    Add more
                  </Typography>
                </Button>
              </Box>

              <TextField
                sx={{ marginTop: 3 }}
                fullWidth
                label="Product style code"
                id="productstylecode"
                onChange={(e) => setproductStyleCode(e.target.value)}
                value={productStyleCode}
                required
                InputProps={{
                  readOnly: userdetail[0]?.role === "qa" ? true : false,
                }}
              />

              {tags.map((item, index) => (
                <Autocomplete
                  disabled={userdetail[0]?.role === "qa" ? true : false}
                  key={index}
                  fullWidth
                  label="Tags"
                  multiple
                  onChange={(e, value) => {
                    setTagsValues((vals) => ({
                      ...vals,
                      [item]: { value, typeName: item },
                    }));
                  }}
                  // disabled={editing}
                  value={tagsValues[item]?.value ?? []}
                  id="tags-filled"
                  options={[]}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{ marginTop: 3 }}
                      id="outlined-basic"
                      label={item}
                      variant="outlined"
                      {...params}
                      required
                    />
                  )}
                />
              ))}
              <TextField
                sx={{ marginTop: 3 }}
                fullWidth
                label="Product brand"
                id="productbrands"
                onChange={(e) => setproductBrand(e.target.value)}
                value={productBrand}
                required
                InputProps={{
                  readOnly: userdetail[0]?.role === "qa" ? true : false,
                }}
              />
              {subCategoryType.length > 0 ? (
                <FormControl sx={{ minWidth: "100%", marginTop: 3 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Select category*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={categoryType}
                    onChange={(e) => setCategoryType(e.target.value)}
                    fullWidth
                    label="Select category*"
                  >
                    <MenuItem value="None">
                      <em>None</em>
                    </MenuItem>
                    {subCategoryType.map((item, index) => (
                      <MenuItem value={item.name} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}

              {fitData.length > 0 ? (
                <FormControl sx={{ minWidth: "100%", marginTop: 3 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Select fit*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={fit}
                    onChange={(e) => setFit(e.target.value)}
                    fullWidth
                    label="Select fit*"
                  >
                    <MenuItem value="None">
                      <em>None</em>
                    </MenuItem>
                    {fitData.map((item, index) => (
                      <MenuItem value={item.name} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}

              {fabricData.length > 0 ? (
                <FormControl sx={{ minWidth: "100%", marginTop: 3 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Select fabric*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={fabric}
                    onChange={(e) => setFabric(e.target.value)}
                    fullWidth
                    label="Select fabric*"
                  >
                    <MenuItem value="None">
                      <em>None</em>
                    </MenuItem>
                    {fabricData.map((item, index) => (
                      <MenuItem value={item.name} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
              <Box
                component="form"
                sx={{
                  "& > :not(style)": {
                    marginTop: 3,
                    marginBottom: 3,
                    width: "100%",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <Typography variant="h5">Attributes</Typography>

                {attributes
                  .filter((el) => el !== "Color")
                  .map((item, index) => (
                    <Autocomplete
                      key={index}
                      disabled={userdetail[0]?.role === "qa" ? true : false}
                      fullWidth
                      label="Color"
                      multiple
                      onChange={(e, value) => {
                        setAttributeValues((vals) => ({
                          ...vals,
                          [item]: { value, typeName: item },
                        }));
                      }}
                      // disabled={editing}
                      value={attributeValues[item]?.value ?? []}
                      id="tags-filled"
                      options={[]}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          id="outlined-basic"
                          label={item}
                          variant="outlined"
                          {...params}
                          required
                        />
                      )}
                    />
                  ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TextField
                id="outlined-multiline-static"
                label="Regular price"
                sx={{ width: "12%", marginRight: "1%" }}
                onChange={(e) =>
                  setproductBasePrice(e.target.value < 0 ? 0 : e.target.value)
                }
                disabled={location.state.data.isNew === true ? false : true}
                value={productBasePrice}
                required
                InputProps={{
                  readOnly: userdetail[0]?.role === "qa" ? true : false,
                }}
                type="number"
              />
              <TextField
                id="outlined-multiline-static"
                label="Sale price"
                sx={{ width: "12%", marginRight: "1%" }}
                onChange={(e) =>
                  setproductSalePrice(e.target.value < 0 ? 0 : e.target.value)
                }
                value={productSalePrice}
                required
                InputProps={{
                  readOnly: userdetail[0]?.role === "qa" ? true : false,
                }}
                type="number"
              />
              <TextField
                id="outlined-multiline-static"
                label="Weight(in kg)"
                sx={{ width: "12%", marginRight: "1%" }}
                onChange={(e) =>
                  setproductWeight(e.target.value < 0 ? 0 : e.target.value)
                }
                value={productWeight}
                required
                InputProps={{
                  readOnly: userdetail[0]?.role === "qa" ? true : false,
                }}
                type="number"
              />
              <TextField
                id="outlined-multiline-static"
                label="Height(in cm)"
                sx={{ width: "12%", marginRight: "1%" }}
                onChange={(e) =>
                  setproductHeight(e.target.value < 0 ? 0 : e.target.value)
                }
                value={productHeight}
                required
                InputProps={{
                  readOnly: userdetail[0]?.role === "qa" ? true : false,
                }}
                type="number"
              />
              <TextField
                id="outlined-multiline-static"
                label="Length(in cm)"
                sx={{ width: "12%", marginRight: "1%" }}
                onChange={(e) =>
                  setproductLength(e.target.value < 0 ? 0 : e.target.value)
                }
                value={productLength}
                required
                InputProps={{
                  readOnly: userdetail[0]?.role === "qa" ? true : false,
                }}
                type="number"
              />
              <TextField
                id="outlined-multiline-static"
                label="Width(in cm)"
                sx={{ width: "12%" }}
                onChange={(e) =>
                  setproductWidth(e.target.value < 0 ? 0 : e.target.value)
                }
                value={productWidth}
                required
                InputProps={{
                  readOnly: userdetail[0]?.role === "qa" ? true : false,
                }}
                type="number"
              />

              <Typography variant="h5" sx={{ marginTop: 3 }}>
                Color
              </Typography>
              {variants.map((item, idx) => (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",

                    borderBottom: 2,
                    paddingBottom: 3,
                    borderBottomColor: "darkgray",

                    "& > :not(style)": {
                      m: 1,
                      width: "30%",
                      height: 128,
                    },
                  }}
                >
                  <Paper elevation={0}>
                    {" "}
                    <TextField
                      id="value"
                      name="value"
                      value={item.value}
                      label="Value"
                      variant="outlined"
                      onChange={(e) => handleChangeValue(e, idx)}
                      required
                      InputProps={{
                        readOnly: userdetail[0]?.role === "qa" ? true : false,
                      }}
                    />
                    <Typography
                      sx={{
                        marginTop: 3,
                        color: "indigo",
                        textTransform: "uppercase",
                      }}
                    >
                      SKU: {item.sku}
                    </Typography>
                  </Paper>
                  <Paper elevation={0}>
                    <input
                      accept="image/*"
                      name="thumb"
                      id="thumb"
                      // multiple
                      type="file"
                      onChange={(e) => handleChangeThumnail(e)}
                      style={{
                        display: "none",
                        width: "106px",
                        height: "106px",
                      }}
                      disabled={userdetail[0]?.role === "qa" ? true : false}
                    />

                    {loading1 === true ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <label htmlFor="thumb">
                        {item.thumb !== "" ? (
                          <img
                            alt=""
                            src={item.thumb}
                            style={{
                              width: "106px",
                              height: "106px",
                            }}
                          />
                        ) : (
                          <Button
                            component="span"
                            sx={{
                              backgroundColor: "#CEF4FF",
                              width: "125px",
                              height: "125px",
                              border: "dotted",
                              borderWidth: "2px",
                              borderColor: "blue",
                            }}
                            disabled={
                              userdetail[0]?.role === "qa" ? true : false
                            }
                            onClick={() => setIndexThumb(idx)}
                          >
                            <Add color="primary" />
                          </Button>
                        )}
                      </label>
                    )}

                    <Typography>Thumbnail image</Typography>
                  </Paper>

                  {item?.gallery?.map((el, index) => (
                    <Paper elevation={0} key={index}>
                      <input
                        accept="image/*"
                        name="gallery"
                        id="gallery"
                        // multiple
                        type="file"
                        onChange={(e) => handleChangeGallery(e)}
                        style={{
                          display: "none",
                          width: "106px",
                          height: "106px",
                        }}
                        disabled={userdetail[0]?.role === "qa" ? true : false}
                      />

                      {loading2 === true && indexThumb === idx ? (
                        <Box sx={{ display: "flex" }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <label htmlFor="gallery">
                          {el.link !== "" ? (
                            <img
                              alt=""
                              src={el.link}
                              style={{
                                width: "106px",
                                height: "106px",
                              }}
                            />
                          ) : (
                            <Button
                              component="span"
                              sx={{
                                backgroundColor: "#CEF4FF",
                                width: "125px",
                                height: "125px",
                                border: "dotted",
                                borderWidth: "2px",
                                borderColor: "blue",
                              }}
                              disabled={
                                userdetail[0]?.role === "qa" ? true : false
                              }
                              onClick={() => setIndexThumb(idx)}
                            >
                              <Add color="primary" />
                            </Button>
                          )}

                          {index === 0 ? null : (
                            <Button
                              startIcon={
                                <Remove
                                  color="primary"
                                  sx={{ backgroundColor: "#CEF4FF" }}
                                />
                              }
                              disabled={
                                userdetail[0]?.role === "qa" ? true : false
                              }
                              variant="text"
                              color="primary"
                              onClick={() => removeGalleryImage(idx, index)}
                            ></Button>
                          )}
                        </label>
                      )}

                      <Typography>
                        Gallery image {index === 0 ? null : "(" + index + ")"}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              ))}
              <Box style={{ display: "flex", marginTop: "1vw" }}>
                <Button
                  startIcon={
                    <Add color="primary" sx={{ backgroundColor: "#CEF4FF" }} />
                  }
                  variant="text"
                  color="primary"
                  onClick={handleAddVariants}
                  disabled={userdetail[0]?.role === "qa" ? true : false}
                >
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      textTransform: "none",
                    }}
                  >
                    Add variant
                  </Typography>
                </Button>
              </Box>
            </CustomTabPanel>
          </>
        </Box>
      </Box>
    </Box>
  );
}
