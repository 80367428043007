import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import { visuallyHidden } from "@mui/utils";
import MenuBar from "../../components/menu/MenuBar";
import DrawerHeader from "../../components/drawerHeader/DrawerHeader";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { Chip, Zoom } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Brightness1, Edit, Search, Visibility } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import {
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import Button from "@mui/material/Button";
import { db } from "../../firebase";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "orderid",
    numeric: true,
    disablePadding: false,
    label: "Order id",
  },

  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Thumbnail",
  },
  {
    id: "qty",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "variants",
    numeric: true,
    disablePadding: false,
    label: "Variants",
  },
  {
    id: "method",
    numeric: true,
    disablePadding: false,
    label: "Payment method",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Delivery date",
  },

  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Total amount",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ paddingLeft: 2 }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function NewOrders() {
  const navigate = useNavigate();
  let userdetail = JSON.parse(localStorage.getItem("userdetail"));

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [orderList, setOrderList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [statusData, setStatusData] = React.useState([]);
  const [idx, setIdx] = React.useState(0);
  const [orderId, setOrderId] = React.useState("");

  const handleChangeStatus = async () => {
    const list = [...statusData];
    list[idx]["itemStatus"] = status;

    await updateDoc(doc(db, "dashboard_orders", orderId), {
      orderItems: list,
    });
    alert("Order status changed successfully");
    window.location.reload();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(orderList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [orderList, order, orderBy, page, rowsPerPage]
  );

  React.useEffect(() => {
    if (searchText !== "") {
      handleSearch();
    } else {
      getOrders();
    }
  }, [searchText]);

  const handleSearch = async () => {
    setLoading(true);

    const filteredArray = orderList.map((mainObj) => ({
      ...mainObj,
      orderItems: mainObj.orderItems.filter((subObj) =>
        subObj.productName.toLowerCase().includes(searchText)
      ),
    }));

    setOrderList(filteredArray.flat());

    setLoading(false);
  };

  const getOrders = async () => {
    setLoading(true);
    const q = query(collection(db, "dashboard_orders"));
    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (userdetail[0]?.role === "vendor") {
        const filteredArray = newData.map((mainObj) => ({
          ...mainObj,
          orderItems: mainObj.orderItems.filter(
            (subObj) =>
              subObj.vendorId === userdetail[0].id &&
              subObj.itemStatus === "Pending"
          ),
        }));
        setOrderList(filteredArray.flat());
      } else {
        const filteredArray = newData.map((mainObj) => ({
          ...mainObj,
          orderItems: mainObj.orderItems.filter(
            (subObj) => subObj.itemStatus === "Pending"
          ),
        }));

        setOrderList(filteredArray.flat());
      }
      setLoading(false);
    });
  };

  const handleOrderView = (row) => {
    navigate("/orderview", { state: { data: row } });
  };

  const handleOpenImage = (url) => {
    setOpen(true);
    setImageUrl(url);
  };
  const handleOpenStatus = async (el, row, i) => {
    setOpen1(true);
    setStatus(el.itemStatus);
    setIdx(i);
    setOrderId(row.id);
    const snap = await getDoc(doc(db, "dashboard_orders", row.id));
    if (snap.exists()) {
      setStatusData(snap.data().orderItems);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <MenuBar menuBarName="New orders" />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box sx={{ width: "100%" }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: "1 1 20%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Orders
            </Typography>
            <Box sx={{ padding: "1em" }}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Search"
                variant="outlined"
                placeholder="Product name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                sx={{ marginRight: "1em", width: "24em" }}
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
              />
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker slotProps={{ textField: { size: "small" } }} />
              </LocalizationProvider> */}
            </Box>
          </Toolbar>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              {loading === true ? (
                <Box sx={{ width: "100%" }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              ) : (
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e) => setStatus(e.target.value)}
                    rowCount={orderList.length}
                  />
                  <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <Avatar
                        alt=""
                        src={imageUrl}
                        variant="rounded"
                        sx={{
                          width: 466,
                          height: 486,
                          display: "-webkit-inline-flex",
                        }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpen(false)}>Close</Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={open1}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                  >
                    <DialogContent>
                      <FormControl fullWidth sx={{ width: 200 }}>
                        <InputLabel id="demo-simple-select-label">
                          Change status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={status}
                          label="Change status"
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <MenuItem value="Pending">Pending</MenuItem>
                          <MenuItem value="Shipped">Shipped</MenuItem>
                          <MenuItem value="Outfordeliver">
                            Out for delivered
                          </MenuItem>
                          <MenuItem value="Delivered">Delivered</MenuItem>
                          <MenuItem value="Cancelled">Cancelled</MenuItem>
                        </Select>
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpen1(false)}>Cancel</Button>
                      <Button onClick={handleChangeStatus} variant="contained">
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <TableBody>
                    {visibleRows.map((row, index) =>
                      row.orderItems.map((el, i) => (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          tabIndex={-1}
                          key={i}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ paddingLeft: 2 }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {el.productName}
                              {el.itemStatus === "Pending" ? (
                                <Chip
                                  icon={
                                    <Brightness1
                                      style={{
                                        width: 5,
                                        height: 5,
                                        color: "#B2B625",
                                      }}
                                    />
                                  }
                                  label="Pending"
                                  sx={{
                                    backgroundColor: "#F8FAB0",

                                    color: "#000",
                                    fontSize: 10,
                                    height: 20,
                                  }}
                                  variant="outlined"
                                />
                              ) : null}

                              <Typography
                                sx={{
                                  textTransform: "uppercase",
                                  fontSize: 10,
                                  marginTop: 1,
                                }}
                              >
                                SKU: {el.sku}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              sx={{
                                textTransform: "uppercase",
                                fontSize: 12,
                              }}
                            >
                              {row.id}
                            </Typography>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              onClick={() => handleOpenImage(el.thumb)}
                            >
                              <Avatar
                                alt=""
                                src={el.thumb}
                                variant="rounded"
                                sx={{
                                  width: 66,
                                  height: 86,
                                  display: "-webkit-inline-flex",
                                }}
                              />
                            </IconButton>
                          </TableCell>
                          <TableCell align="right">{el.qty}</TableCell>
                          <TableCell align="right">
                            {el.variants.map((e) => e.variantKey).join(", ")} :{" "}
                            {el.variants.map((e) => e.variantValue).join(", ")}
                          </TableCell>
                          <TableCell align="right">
                            {row.paymentStatus[0].method}
                          </TableCell>
                          <TableCell align="right">{el.deliveryDate}</TableCell>
                          <TableCell align="right">
                            {parseInt(el.salePrice) * parseInt(el.qty)}
                          </TableCell>

                          <TableCell align="right">
                            <Box>
                              <Tooltip
                                title="View"
                                TransitionComponent={Zoom}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => handleOrderView(row)}
                                >
                                  <Visibility color="primary" />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                title="Edit"
                                TransitionComponent={Zoom}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => handleOpenStatus(el, row, i)}
                                >
                                  <Edit color="primary" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                    {emptyRows > 0 && (
                      <TableRow>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={orderList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
